import React, { useState } from 'react';

function LinkDialog({ isOpen, onClose, onSubmit }) {
    const [url, setUrl] = useState('');

    const handleSubmit = () => {
        onSubmit(url);
        onClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="dialogOverlay">
            <div className="dialogContainer">
                <div className="dialogHeader">
                    Unesite URL
                    <span className="closeDialog" onClick={onClose}>x</span>
                </div>
                <div className="dialogMain">
                    <input 
                        type="text" 
                        value={url} 
                        onChange={(e) => setUrl(e.target.value)} 
                        placeholder="URL link" 
                    />
                </div>
                <div className="dialogFooter">
                    <button className='AppoDialogButton' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default LinkDialog;
