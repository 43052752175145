import './Appointments.css'
import { collection, query, where, onSnapshot, updateDoc } from "firebase/firestore";
import { db, auth, analytics } from '../../config/firebase-config';
import { useState, useEffect } from 'react';
import AppointmentDialog from '../AppointmentDialog/AppointmentDialog';
import { getDocs,deleteDoc, doc } from 'firebase/firestore';
import { useStateValue } from '../StateProvider';
import { id } from 'date-fns/locale';
import { set } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app  } from '../../config/firebase-config';
import { logEvent } from 'firebase/analytics';
import RingLoader from 'react-spinners/RingLoader';


function Appointments() {
    const [appoArray, setAppoArray] = useState([]);
    const [isDialogOpen2,setIsDialogOpen2] = useState(false);
    const [price, setPrice] = useState();
    const [account, setAccount] = useState();
    const [userType, setUserType] = useState();
    const [account2, setAccount2] = useState();
    const [description, setDescription] = useState();
    const [{basket}] = useStateValue();
    const [id,setId] = useState();
    const [location, setLocation] = useState();
    const [appoLoading, setAppoLoading] = useState(false);
    const navigate = useNavigate();

    const functions = getFunctions(app);
    const sendEmail = httpsCallable(functions, 'sendEmail');    

    useEffect(() => {
        if (auth.currentUser && !basket[0]?.privacyPoliceAccepted) {
            navigate('/terms-and-conditions')
            alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite politiku privatnosti.')

        } else if (auth.currentUser && !basket[0]?.termsAndConditionAccepted) {
            navigate('/privacy-policy')
            alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite uslove korišćenja.')
        }
    }, [])
      

    useEffect(() => {
        setAppoLoading(true); // Start loading

        const q = query(
            collection(db, "Clients", auth.currentUser.uid, "Appointments"),
            where("ended", "==", false),
            where("cancelled", "==", false)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const appointmentsArray = [];
            querySnapshot.forEach((doc) => {
                let dataObject = doc.data();
                dataObject.id = doc.id;
                let [nameN, nameS] = dataObject.id.split(' ');
                dataObject.name = nameN;
                dataObject.surname = nameS;
                appointmentsArray.push(dataObject);
            });
            setAppoArray(appointmentsArray);

            setAppoLoading(false); // Stop loading after data is fetched
        });

        // Remove the redundant setAppoLoading(true);

        return () => {
            unsubscribe();
        };
    }, []);


    /* useEffect(() => {
        let isSubscribed = true;
        const imgUrls = new Map(); // Map to store image URLs

        const updateAppoArray = () => {
            if (isSubscribed) {
                setAppoArray(prevAppoArray => prevAppoArray.map(appo => {
                    const newImgUrl = imgUrls.get(appo.id);
                    return newImgUrl && newImgUrl !== appo.img ? {...appo, img: newImgUrl} : appo;
                }));
            }
        };
 
        appoArray.forEach((appo) => {
            if (appo.name && appo.surname) {
                const q = query(collection(db, "Clients"), where("name", "==", appo.name), where("surname", "==", appo.surname));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const newImgUrl = doc.data().imageURL;

                        if (newImgUrl && imgUrls.get(appo.id) !== newImgUrl) {
                            imgUrls.set(appo.id, newImgUrl);
                            updateAppoArray(); // Update the appoArray
                        }
                    });
                });
            }
        });

        return () => {
            isSubscribed = false;
        };
    }, []); */
    

    
    

    async function cancelAppointmentById(docId, appointmentDoc) {
        let firstSpaceIndex = docId.indexOf(' '); // Find the first space
        logEvent(analytics, 'appointment_canceled', {
            receiver_name: docId,
            price: appointmentDoc.price,
            date: appointmentDoc.date
        });
// Split the string into two parts based on the last space index
        let appoN = docId.substring(0, firstSpaceIndex); // Everything before the last space
        let appoS = docId.substring(firstSpaceIndex + 1); // Everything after the last space
        let currentUser = basket[0].name + ' ' + basket[0].surname;
        
        const docRef = doc(db, "Clients", auth.currentUser.uid, "Appointments", docId);
        await updateDoc(docRef, { cancelled: true }); // Update appointment document to mark it as cancelled
        
        const clientCollection = collection(db, "Clients");
        const clientQuery = query(clientCollection, where("name", "==", appoN), where("surname", "==", appoS.split('_')[0] ));
        const clientQuerySnapshot = await getDocs(clientQuery);
        let senderEmail = null
        for (const clientDoc of clientQuerySnapshot.docs) {
            const clientData = clientDoc.data(); // Get the data from the document
            senderEmail = clientData.email; // Extract email

            // Check if the email is valid before proceeding
            if (!senderEmail) {
                console.error("Email not found in document:", clientDoc.id);
                continue; // Skip to the next iteration if no email is found
            };
        }
        try {
            const emailData2 = {
                to: senderEmail, // Replace with the recipient's email address
                subject: 'Otkazan termin',
                messageBody: `
                      <b>Korisnik <a href="https://evawellbeing.app/profile/${basket[0].name.toLowerCase() + '.' + basket[0].surname.toLowerCase()}">${basket[0].name + ' ' + basket[0].surname}</a> je otkazao/la termin.</b>
  <br><br>
  <b>Link ka aplikaciji:</b> <a href="https://evawellbeing.app/">Eva Wellbeing</a>
`          };
            const result = await sendEmail(emailData2);
            console.log('Email sent successfully:', result.data);
        } catch (error) {
            console.error("Error sending email:", error);
        }
    }

    function showAppo() {
        if (appoLoading) {
            // Return the loader when loading
            return (
                <RingLoader
                    color={'#4dffdb'}
                    loading={appoLoading}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            );
        } else {
            // Sort the array before mapping
            appoArray.sort((a, b) => new Date(a.date) - new Date(b.date));

            // Return the mapped appointments
            return appoArray.map((appo) => {
                let date = new Date(appo.date.toDate());
                let dateString = date.toLocaleString('sr-RS', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false, // Use 24-hour format
                });
                return (
                    <div className="Appo" key={appo.id}>
                        <div className="AppoProfile">
                            <div className="AppoImageContainer">
                                <img className="AppoImage" src={appo.img} />
                            </div>
                            <div className="AppoInfo">
                                <div className="AppoName">{appo.id.split('_')[0]}</div>
                                <div className="AppoDate">{dateString}</div>
                            </div>
                        </div>
                        <div className="AppoButtons">
                            <button
                                className="AppoButton"
                                onClick={() => handlePlusClick2(appo.id, appo)}
                            >
                                Info
                            </button>
                            <button
                                className="AppoButton"
                                onClick={() => {
                                    cancelAppointmentById(appo.id, appo);
                                }}
                            >
                                Otkaži
                            </button>
                        </div>
                    </div>
                );
            });
        }
    }


    function handlePlusClick2(ids,doc) {
        const foundObject = appoArray.find(item => item.id === ids);
        let location1 = foundObject.meetLink ? foundObject.meetLink : foundObject.address;
        let id1 = foundObject.id;
        let userType1 = foundObject.userType;
        let account1 = foundObject.account;
        let price1 = foundObject.price;
        let account2 = foundObject.account2;
        let description = foundObject.description2; 
        setLocation(location1);
        setId(id1);
        setUserType(userType1);
        setAccount(account1)
        setPrice(price1);
        setIsDialogOpen2(true);
        setAccount2(account2);
        setDescription(description);
        logEvent(analytics, 'open_appointment_info_dialog', {
            receiver_name: doc.id,
            price: doc.price,
            date: doc.date
        })

      }
    return (
        <div className="Appointments">
            {showAppo()}
            <AppointmentDialog account2={account2} description={description} id={id} userType={userType} account={account} price = {price} location={location}  isOpen={isDialogOpen2}  onClose2={() => setIsDialogOpen2(false)}/>
        </div>
    )
}

export default Appointments;
