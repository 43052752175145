import "./AppointmentsSpec.css";
import { doc, onSnapshot } from "firebase/firestore";
import { analytics, auth, db } from "../../config/firebase-config";
import { useEffect, useState } from "react";
import SpecDiv from "../SpecDiv/SpecDiv";
import HourDialog from '../HourDialog/HourDialog'
import { getDoc } from "firebase/firestore";
import AppointmentDialog from "../AppointmentDialog/AppointmentDialog"
import { logEvent } from "firebase/analytics";


function AppointmentsSpec(props) {
  const [targetDocRef1, setTargetDocRef1] = useState({})  
  const user = auth.currentUser.uid;
  const currentDate = props.currentDate;
  const englishDay = currentDate.slice(0, 3).toUpperCase();
  const [nameN, setNameN] = useState('');
  const [nameS, setNameS] = useState('');
  let [location, setLocation] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  function translateDayToSerbian(englishDay) {
    const translation = {
      'MON': 'PON', // Monday
      'TUE': 'UTO', // Tuesday
      'WED': 'SRE', // Wednesday
      'THU': 'ČET', // Thursday
      'FRI': 'PET', // Friday
      'SAT': 'SUB', // Saturday
      'SUN': 'NED'  // Sunday
    };

    return translation[englishDay] || 'Invalid day';
  }
  
  const serbianDay = translateDayToSerbian(englishDay);
  
  useEffect(() => {
    let unsubscribe;

    const fetchDocAndListen = async () => {
        const clientInfo = doc(db, "Clients", auth.currentUser.uid);
        const specificDateRef = doc(db, "Clients", auth.currentUser.uid, "SpecificDates", currentDate);
        const specificDateSnap = await getDoc(specificDateRef);

        let targetDocRef; 

        // Check if the SpecificDates document exists
        if (specificDateSnap.exists()) {
            targetDocRef = specificDateRef;
            setTargetDocRef1(targetDocRef);
        } else {
            targetDocRef = doc(db, 'Clients', user, 'WorkingTime', serbianDay);
            setTargetDocRef1(targetDocRef);
        }

        unsubscribe = onSnapshot(targetDocRef, (doc) => {
          const data = doc.data();
          
            // Check if data is defined
            if (data && data.hours) {
                props.setTimes((prev) => [...prev, ...data.hours]);
            }
        });
    };

    // Immediately invoked async function inside useEffect
    (async () => {
        await fetchDocAndListen();
    })();

    return () => unsubscribe && unsubscribe();
}, [user, englishDay, currentDate, props.process]);


  function SpecCreator() {
    const uniqueArray = [...new Set(props.times)];
    return uniqueArray.sort((a, b) => {
      const [hoursA, minutesA] = a.split(':').map(Number);
      const [hoursB, minutesB] = b.split(':').map(Number);
      
      return (hoursA * 60 + minutesA) - (hoursB * 60 + minutesB);
  }).map(hour => {
      return (
       <SpecDiv setImageUrl = {setImageUrl} setLocation={setLocation} setNameN={setNameN} setNameS={setNameS} setIsDialogOpen2={props.setIsDialogOpen2} onClick = {handlePlusClick2} key={hour} appoNames={props.appoNames} appoHours={props.appoHours} serbianDay={serbianDay} currentDate={currentDate} targetDocRef1 = {targetDocRef1} hour={hour} setTimes={props.setTimes}/>
      )
    })
  }

  function handlePlusClick() {
    props.setIsDialogOpen(true);
    logEvent(analytics,'open_hour_dialog')
  }
  function handlePlusClick2() {
    props.setIsDialogOpen2(true);
  }
 
  return (
    <div className='AppointmentsSpec'>
      {SpecCreator()}
      <div className='addHour' onClick = {handlePlusClick}> + </div>
      <HourDialog   currentDate={props.currentDate} times={props.times} setTimes = {props.setTimes} serbianDay={serbianDay} isOpen={props.isDialogOpen} onClose={() => props.setIsDialogOpen(false)} />
      <AppointmentDialog  nameN={nameN} nameS={nameS} imageUrl={imageUrl}  location={location}  isOpen={props.isDialogOpen2} onClose2={() => props.setIsDialogOpen2(false)}  />
    </div>
  );
}
export default AppointmentsSpec;