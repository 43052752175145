export const initialState = {
basket: [],
user: null,
spec: [],
headerName:null,
process:null,
onHomeState: null,
justBooked: false
};

const reducer = (state,action) => {
    console.log(action);
    switch(action.type) {

        case "ADD_TO_BASKET":
        return {
            ...state,
            basket:[action.item]

        }

        case "UPDATE_BASKET_PROPERTY": {
            // Check if basket[0] exists
            if (!state.basket[0]) {
              console.error('Basket is empty or basket[0] does not exist.');
              return state;
            }
      
            const updatedBasket = {
              ...state.basket[0],
              [action.property]: action.value
            };
      
            return {
              ...state,
              basket: [updatedBasket]
            };
          }
        

        case "SET_USER":
            return {
                ...state,
                user: action.user
            }

            case "ADD_TO_SPEC":
                return {
                    ...state,
                    spec:action.spec,
                    
                    
     }
            case "ADD_TO_HEADER":
                return {
                    ...state,
                    headerName:action.headerName}
            case "ADD_TO_PROCESS":
                return {
                    ...state,
                    process:action.process
     }

     case "SET_ON_HOME_STATE":
      return {
        ...state,
        onHomeState: action.onHomeState
      }
     case "UPDATE_JUST_BOOKED":
      return {
        ...state,
        justBooked: action.justBooked
      }
    default:
        return state;
}
}

export default reducer;