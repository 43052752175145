// VideoPopup.js

import './VideoPopUp.css'; // Make sure to create a corresponding CSS file

const VideoPopUp = ({ isPopUpVideoOpen, closeVideoPopup, videoUrl }) => {
  if (!isPopUpVideoOpen) return null;

  return (
    <div className="video-popup">
      <div className="video-popup-content">
        <button className="close-btn" onClick={closeVideoPopup}>X</button>
        <video className='video' controls src={videoUrl} autoPlay />
      </div>
    </div>
  );
};

export default VideoPopUp;
