import React, { useState } from 'react';
import './AboutDialog.css';
import TextareaAutosize from '@mui/material/TextareaAutosize' 
import { doc, setDoc } from "firebase/firestore";
import { db, auth, analytics } from "../../config/firebase-config"
import { logEvent } from 'firebase/analytics';

function AboutDialog({ isDialogOpen,setIsDialogOpen, onClose, setAboutMe, aboutMe }) {


    

    const handleSubmit = async () => {
        setIsDialogOpen(false)
    
        try {
            const clientRef = doc(db, 'Clients', auth.currentUser.uid);
            await setDoc(
                clientRef,
                { aboutMe: aboutMe },
                { merge: true }
            );
            logEvent(analytics, 'long_description_set');
            
        } catch (error) {
            console.error('Error saving aboutMe:', error);
        }
    };

    if (!isDialogOpen) {
        return null;
    }

    return (
        <div className="dialogOverlay">
            <div className="dialogContainer">
                <div className="dialogHeader">
                    Napiši nešto o sebi
                    <span className="closeDialog" onClick={onClose}>x</span>
                </div>
                <div className="dialogMain">
                    <TextareaAutosize 
                        aria-label="minimum height" 
                        minRows={6} 
                        placeholder="Vaš opis..." 
                        value={aboutMe} 
                        onChange={e => setAboutMe(e.target.value)} 
                        maxLength={750}
                        
                    />
                </div>
                <div className="dialogFooter">
                    <button className='submitButton' onClick={handleSubmit}>Sačuvaj</button>
                </div>
            </div>
        </div>
    );
}

export default AboutDialog;
