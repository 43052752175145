import AS2 from "./AppointmentSettings2"
import { useNavigate, useSearchParams } from "react-router-dom";
import {useState, useEffect} from "react";
import { doc, collection, getDocs, query, where } from "firebase/firestore";
import { analytics, auth } from "../../config/firebase-config";
import { db } from "../../config/firebase-config";
import { Timestamp} from "firebase/firestore";
import { useStateValue } from "../StateProvider";
import { logEvent } from "firebase/analytics";

function ASC2() {
    const currentUserId = auth.currentUser.uid;
    const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' };
    const [currentDate,setCurrentDate] = useState(new Date().toLocaleString('en-US', options));
    let   [times, setTimes] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = useState(false);
    const navigate = useNavigate()
    const [AppointmentsDocs, setAppointmentsDocs] = useState([]);
    const [appoHours, setAppoHours] = useState([]);
    const [appoLinksArray, setAppoLinksArray] = useState([]);
    const [currentDateObject, setCurrentDateObject] = useState(new Date());
    const [loading2, setLoading2] = useState(true);
    const [appoNames, setAppoNames] = useState();
    const [{ process }] = useStateValue();
   
    
    

    const startOfDay = new Date(currentDateObject);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(currentDateObject);
    endOfDay.setHours(23, 59, 59, 999);
    
    function onClickEdit() {
        navigate('../appointmentsSetting')
        logEvent(analytics,"go_to_appointment_settings")
    };
    const formatShortWeekday = (locale, date) => {
        return date.toLocaleString(locale, { weekday: 'short' }).charAt(0);
    };

    async function fetchAppointments() {
        const userDocRef = doc(db, "Clients", currentUserId);
        const appointmentsCollectionRef = collection(userDocRef, "Appointments");
        const appoRef = query(appointmentsCollectionRef,
          where('date', '>=', Timestamp.fromDate(startOfDay)),
          where('date', '<=', Timestamp.fromDate(endOfDay)));
        const appointmentsSnapshot = await getDocs(appoRef);
        const appointments = [];
      
        appointmentsSnapshot.forEach((doc) => {
          appointments.push({ id: doc.id, ...doc.data() });
        });
      
        return appointments;
      }
      
      useEffect(() => {
        setTimes([]);
        setAppoHours([]);
        const appoHoursArray = [];
        const appoNamesArray = [];
        fetchAppointments().then((appointments) => {
            setAppointmentsDocs(appointments);
            appointments.forEach((appo) => {
                let realAppoHour = appo.date;
                let appoHour = realAppoHour.toDate().getHours();
                const hourString = String(appoHour).padStart(2, '0') + ":00";
                appoHoursArray.push(hourString);
                let appoName = appo.id;
                let appoLink;
                if(appo.meetLink){
                appoLink = appo.meetLink}
                appoNamesArray.push({appoName:appoName, appoTime:hourString, appoLink:appoLink});
            });
            setAppoHours(appoHoursArray);
            setAppoNames(appoNamesArray)
            
        });
    }, [currentDate]);  
    
    useEffect(() => {
        if (appoHours.length > 0) {
            setTimes((prev) => {
                return [...prev, ...appoHours];
            });
        }
    }, [appoHours,process] );  // you might only need appoHours as a dependency here
    
    useEffect(() => {
        setLoading2(false);
    }, [times, appoNames]);  // this effect will run once times is changed
    
      useEffect(() => {
      })
    return (
    <AS2 appoNames={appoNames} appoHours={appoHours} loading2 = {loading2} appointments={AppointmentsDocs} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} isDialogOpen2={isDialogOpen2} setIsDialogOpen2={setIsDialogOpen2} times={times} setTimes={setTimes} options={options} currentDate={currentDate} setCurrentDate={setCurrentDate} onClickEdit={onClickEdit}  formatShortWeekday={formatShortWeekday} setCurrentDateObject = {setCurrentDateObject}/>
    )
}
export default ASC2; 