
import { useLocation } from "react-router-dom";

export default function DropdownList(props) {
    const location = useLocation();
    const handleChange = (e) => {
      const type = e.target.value;
  
      // Use the setType from props
      props.setType(type);
  
      // if you need to access the event for showList, you can pass it
      
    };
  
    return (
      <select
        className="Dropdown"
        value={props.currentType} // You need to pass the current type as a prop to set the value of the select element
        onChange={handleChange}
      ><option value="psychotherapy">
        {location.pathname.length > 1 ? "" : "Terapeuti i Savetnici"}
       </option>
        <option value="razgovor">
          {location.pathname.length > 1 ? "" : "Razgovori"}
        </option>
        <option value="savetnik">
          {location.pathname.length > 1 ? "" : "EVA Savetnici"}
        </option>
        
      </select>
    );
  }
  