import React, {useEffect,useState} from 'react';
import './App.css';
import { Route, RouterProvider, createBrowserRouter,createRoutesFromElements } from 'react-router-dom';
import Root from "../Root/Root";
import LoginPage from "../LoginPage/LoginPage";
import ProfilePage from "../ProfilePage/ProfilePage";
import {auth} from '../../config/firebase-config';
import MetaPixel from '../../config/MetaPixel';
import { useStateValue } from "../StateProvider";
import { doc, getDoc,collection,query,where,getDocs,updateDoc} from "firebase/firestore";
import { db } from '../../config/firebase-config';
import RatingsList from '../RatingsList/RatingsList';
import About from '../About/About';
import Gallery from '../Gallery/Gallery';
import ASC from '../AppointmetsSettings/AppointmentsSettingContainer';
import ASC2 from '../AppointmentSettings2/AppointmentSettings2Container';
import RingLoader from "react-spinners/RingLoader";
import Booking from '../Booking/Booking';
import ProfilePageLayout from '../ProfilePageLayout/ProfilePageLayout';
import Appointments from '../Appointments/Appointments';
import Chat from '../Chat/Chat';
import { useMemo } from 'react';
import PrivacyPolice from '../PrivacyPolice/PrivacyPolice';
import TermsAndCondition from '../TermsAndCondition/TermsAndCondition'

/* const approveArray = [
  
  'srdjan.timotijevic@gmail.com',

  
]

async function approveClientsByEmail(approveArray) {
    try {
        // Iterate over each email in the approve array
        for (const email of approveArray) {
            // Create a query for documents where the email field matches the current email
            const q = query(collection(db, "Clients"), where("email", "==", email));

            // Execute the query
            const querySnapshot = await getDocs(q);

            // Update each matching document
            querySnapshot.forEach(async (docSnapshot) => {
                const docRef = docSnapshot.ref;
                await updateDoc(docRef, {
                    approved: true
                });
                console.log(`Updated document with email: ${email}`);
            });
        }
    } catch (error) {
        console.error("Error updating documents:", error);
    }
}

// Define the 'approve' array with the emails of the clients to be approved
const approve = ['email1@example.com', 'email2@example.com', 'email3@example.com']; // Replace with actual emails

// Call the function
approveClientsByEmail(approveArray); */

/* ----------------------------------------------------------------------------------------------------------------------------------------------------- */


// Function to fetch UIDs and update ListOfIds document



/* const updateClientsIds = async () => {
  try {
    // Reference to the 'Clients' collection
    const clientsCollectionRef = collection(db, 'Clients');

    // Query documents where 'type' is not 'client'
    const q = query(clientsCollectionRef, where('userType', '!=', 'client'));

    // Fetch the documents
    const querySnapshot = await getDocs(q);

    // Initialize objects to store data
    const idsForRazgovor = {};
    const idsForSavetnik = {};
    const idsForPsychotherapy = {};

    // Iterate over each document and organize data by type
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      switch (data.userType) {
        case 'razgovor':
          idsForRazgovor[doc.id] = data;
          break;
        case 'savetnik':
          idsForSavetnik[doc.id] = data;
          break;
        case 'psychotherapy':
          idsForPsychotherapy[doc.id] = data;
          break;
        // Add more cases if needed
      }
    });

    // Update corresponding documents in 'ClientsIds' collection
    await updateDoc(doc(db, 'ClientsIds', 'ListOfIds1'), { ids: idsForRazgovor });
    await updateDoc(doc(db, 'ClientsIds', 'ListOfIds2'), { ids: idsForSavetnik });
    await updateDoc(doc(db, 'ClientsIds', 'ListOfIds3'), { ids: idsForPsychotherapy });

    console.log('ClientsIds updated successfully');
  } catch (error) {
    console.error('Error updating ClientsIds:', error);
  }
};

updateClientsIds(); */

/* ----------------------------------------------------------------------------------------------------------------------------------------------------- */


/* const clientsColRef = collection(db, 'Clients');

const updateClientsWithWorkingDays = async () => {
  try {
      const querySnapshot = await getDocs(clientsColRef);
      querySnapshot.forEach(async (clientDoc) => {
      const clientId = clientDoc.id;
      const workingTimeSubcolRef = collection(db, `Clients/${clientId}/WorkingTime`);
      const specificDatesSubcolRef = collection(db, `Clients/${clientId}/SpecificDates`);

      // Initialize arrays for IDs
      const workingTimeIds = [];
      const specificDatesIds = [];

      // Fetch IDs from WorkingTime subcollection, if it exists
      const workingTimeSnapshot = await getDocs(workingTimeSubcolRef);
      workingTimeSnapshot.forEach((doc) => {
        workingTimeIds.push(doc.id);
      });

      // Fetch IDs from SpecificDates subcollection, if it exists
      const specificDatesSnapshot = await getDocs(specificDatesSubcolRef);
      specificDatesSnapshot.forEach((doc) => {
        specificDatesIds.push(doc.id);
      });

      // Note: If a subcollection does not exist or is empty, the corresponding array will be empty

      // Prepare the workingDays object with potentially empty arrays
      const workingDays = {
        workingTime: workingTimeIds,
        specificDates: specificDatesIds,
      };

      // Update the client document with workingDays, even if the arrays are empty
      const clientDocRef = doc(db, 'Clients', clientId);
      await updateDoc(clientDocRef, { workingDays });
      console.log(`Updated client ${clientId} with workingDays.`);
    });
  } catch (error) {
    console.error("Error updating clients:", error);
  }
};

updateClientsWithWorkingDays(); */



/* The code below register is there any authenticated user and if it is sends his information to the reducer.
App component is parent to every component on all pages, so app follows is there any auth user after every
render */
function App() {

  const [newUser,setNewUser] = useState(0);
  const [{headerName, user}, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);
  const MemoizedLoginPage = useMemo(() => {
    return <LoginPage setNewUser={setNewUser} newUser={newUser} />;
  }, [newUser]);
  const MemoizedProfilePage = useMemo(() => {
    return <ProfilePage setNewUser={setNewUser} newUser={newUser} />;
  }, [newUser]);

  const [router, setRouter] = useState(null);

  
  

useEffect(() => {
  const routes = createRoutesFromElements(
    <>
      <Route path="/" element={<Root />} />
      <Route path="/login" element={MemoizedLoginPage} />
      <Route path="/profile/:name/" element={<ProfilePageLayout />}>
        <Route path="" element={MemoizedProfilePage} >
          <Route path="ratings" element={<RatingsList />} />
          <Route index element={<About />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="appointments" element={<Appointments />} />
        </Route>
        <Route path="booking" element={<Booking />} />
      </Route>
      <Route path='appointmentsSetting' element={<ASC/>}/>
      <Route path='privacy-policy' element={<PrivacyPolice />}/>
      <Route path='terms-and-conditions' element={<TermsAndCondition />}/>
       <Route path='appointmentsSetting2' element={<ASC2/>}/>
       <Route path='bookingPage' element={<Booking/>}/>
       <Route path='message' element={<Chat/>}/>
       <Route path='message/:name' element={<Chat/>}/>
    </>
  );
  
  const newRouter = createBrowserRouter(routes);
  setRouter(newRouter);

}, [MemoizedLoginPage, MemoizedProfilePage]);



  useEffect(()=> {

    setLoading(true);
    const unsubscribe = auth.onAuthStateChanged(async function (authUser) {
      if(authUser) {
        const docRef = doc(db, "Clients", authUser.uid);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();
        

        dispatch({
          type: 'SET_USER',
          user: authUser,
        });

        dispatch({
          type: "ADD_TO_BASKET",
          item: userData,
          
        });
        
      } else {
        dispatch({
          type:'SET_USER',
          user: null
        });
        
      }
      
      setLoading(false);
      
    });
    
    
    return () => {
      unsubscribe();
    };
  }, []);

  

  



  if (!router || loading) {
    return <div style={{display:"flex",
                        justifyContent:'center',
                      alignItems:"center",
                      height:'100vh'}}><RingLoader
    color={'#4dffdb'}
    loading={loading}
    size={150}
    aria-label="Loading Spinner"
    data-testid="loader"
  /><div></div></div>;
  }

  

  return (
    <>
    <MetaPixel/>
    <RouterProvider router={ router } />
    </>
  
  );
}
export default App;
