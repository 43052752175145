import React from "react";
import "./MiniHeader.css";
import { NavLink } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import { useParams } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase-config";

function MiniHeader(props) {
  const [{ basket, spec }] = useStateValue();
  const profileName = props.myProfile ? basket[0]?.name + '.' + basket[0].surname : props.name;
  const profileNavName = profileName.toLowerCase();
  const { name } = useParams();

  const addGA4Logging = (eventName) => {
    if (props.myProfile) {
      logEvent(analytics, eventName);
    } else {
      logEvent(analytics, eventName, {
        receiver_name: props.headerName,
        receiver_type: props.profileUserType,
        receiver_average_rating: props.averageRating,
        receiver_number_of_ratings: props.receiverUserData.numberOfRatings,
        receiver_number_of_ratings_given: props.receiverUserData.numberOfRatingsGiven,
        receiver_average_rating_given: props.receiverUserData.averageRatingGiven,
        receiver_spec1_exists: props.receiverUserData.spec1 ? 'true' : "false",
        receiver_spec2_exists: props.receiverUserData.spec2 ? 'true' : "false",
        receiver_spec3_exists: props.receiverUserData.spec3 ? 'true' : "false",
        receiver_long_description_set: props.receiverUserData.aboutMe ? 'true' : 'false',
        receiver_short_description_set: props.receiverUserData.description ? 'true' : 'false',
        receiver_profile_picture_set: props.receiverUserData.imageURL ? 'true' : 'false',
        receiver_set_appo: props.receiverUserData.setAppo ? 'true' : 'false',
        receiver_on_feed: props.receiverUserData.onHome ? 'true' : 'false',
        receiver_price: props.receiverUserData.price
      }) 
    }
  }


  return (
    <nav className="MiniHeader">
      <NavLink 
        to={`/profile/${profileNavName}`} 
        className={({ isActive }) => isActive ? "active" : ""}
        onClick={()=>{addGA4Logging('see_profile_info')}}
        end
      >
        Informacije
      </NavLink>

      {basket[0]?.userType === 'client' && props.myProfile || props.profileUserType === "client" ? (
        props.myProfile ? <NavLink to={`/profile/${profileNavName}/appointments`}
          onClick={() => { addGA4Logging('see_appointments') }}
        >
          Termini
        </NavLink> : null
      ) : (
        <>
            <NavLink to={`/profile/${profileNavName}/ratings`}
              onClick={() => { addGA4Logging('see_ratings') }}
            >
            Ocene
          </NavLink>
          {/* <NavLink to={`/profile/${profileNavName}/gallery`}>
            Galerija
          </NavLink> */}

            {props.myProfile ? <NavLink className='appoNav' to={`/profile/${profileNavName}/appointments`}
              onClick={() => { addGA4Logging('see_appointments') }}
            >
            Termini
          </NavLink> : null}
        </>
      )}
    </nav>
  );
}

export default MiniHeader;
