import { useEffect, useState } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { doc, setDoc, arrayUnion } from "firebase/firestore";
import { db, auth } from "../../config/firebase-config";
// Assume other necessary imports here

function RequestPermissionButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Check if the user is on iOS and the app is added to the home screen
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIOS && isInStandaloneMode) {
      // Only show the button if the permission is not already granted
      setShowButton(Notification.permission !== 'granted');
    }
  }, []);

  const requestPermission = () => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        if ('serviceWorker' in navigator) {
            const messaging = getMessaging();
            
            // Get the token for the device
            getToken(messaging, { vapidKey: 'YOUR_VAPID_KEY' })
              .then((currentToken) => {
                if (currentToken) {
                  // Save or update the token in your database
                  const clientDocRef = doc(db, 'Clients', auth.currentUser?.uid);
                  setDoc(clientDocRef, { token: arrayUnion(currentToken) }, { merge: true });
                } else {
                  console.log('No registration token available. Make sure notifications permissions are granted.');
                }
              }).catch((error) => {
                console.error('Error getting registration token:', error);
              });
        
            // Listen to messages when the app is in the foreground
            onMessage(messaging, (payload) => {
              console.log('Message received. ', payload);
              // Process your message as required
            });
          } else {
            console.log('Service Workers are not supported in this environment. Firebase messaging will not be initialized.');
          }
      } else {
        console.log('Notification permission not granted.');
      }
      setShowButton(false);
    });
  };

  if (!showButton) return null;

  return (
<button onClick={requestPermission} style={{position: "fixed", top: "4rem", left: "5rem", backgroundColor: "hsla(174, 40%, 44%, 1)", borderRadius: "0.6rem", fontSize: "0.8rem", color: "white", zIndex: "9999", padding:"0.6rem" }}>Dozvoli notifikacije</button>
  );
}

export default RequestPermissionButton;

// Include this component where it makes sense in your UI, likely as a conditional render
