// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import { getMessaging, isSupported} from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBEMLLyJC6ecE2erm0sarmJaPef1EB9yWk",
  authDomain: "evawellbeing.app",
  projectId: "evaapp-46d63",
  storageBucket: "evaapp-46d63.appspot.com",
  messagingSenderId: "1062402387067",
  appId: "1:1062402387067:web:bf0b5331ef4f05ae4386de",
  measurementId: "G-Q2EGSC5XY0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const googleProvider = new   GoogleAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const messaging = async () => await isSupported() && getMessaging(app);  
export const analytics = getAnalytics(app);


