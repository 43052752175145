import React from 'react';
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './components/StateProvider';
import reducer, { initialState } from './reducer';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="1062402387067-ke3hv9ln5sdhsm1ul3g6edpgjbjhnevp.apps.googleusercontent.com">
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

if ('serviceWorker' in navigator) {
      
  navigator.serviceWorker.register('/firebase-messaging-sw.js' , { updateViaCache: 'none' })
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
} else {
  
  console.log('Service Workers are not supported in this environment.');
  
}if ('serviceWorker' in navigator) {
      
  navigator.serviceWorker.register('/firebase-messaging-sw.js' , { updateViaCache: 'none' })
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
} else {
  
  console.log('Service Workers are not supported in this environment.');
  
}


reportWebVitals();


