import React from 'react';
import './PrivacyPolice.css';
import Header from '../Header/Header';
import { useStateValue } from '../StateProvider';
import { useState } from 'react';
import { auth, db, analytics } from '../../config/firebase-config';
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import SimpleDialog from '../PopUps/SimpleDialog';
import { deleteUser, signOut } from "firebase/auth";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { logEvent } from 'firebase/analytics';

function PrivacyPolice() {
    const [{ basket }, dispatch] = useStateValue();
    const [privacyPoliceAccepted, setPrivacyPoliceAccepted] = useState(basket[0]?.privacyPoliceAccepted);
    const [isOpenSimpleDialog, setIsOpenSimpleDialog] = useState(false);
    const [language, setLanguage] = useState('english');
    
    const handlePrivacyPoliceCheckBoxClick = async () => {

        setPrivacyPoliceAccepted(prev => !prev);
        privacyPoliceAccepted ? logEvent(analytics, 'privacy_police_unchecked') : logEvent(analytics, 'privacy_police_ckecked')

        dispatch({
            type: 'UPDATE_BASKET_PROPERTY',
            property: 'privacyPoliceAccepted', // The name of the property you want to update
            value: !privacyPoliceAccepted // The new value for the property
        });

        await setDoc(doc(db, 'Clients', auth.currentUser.uid), {
            privacyPoliceAccepted: !privacyPoliceAccepted
        }, { merge: true });
        const consentRef = doc(db, 'Clients', auth.currentUser.uid, 'consents', new Date().toISOString());
        await setDoc(consentRef, {
            privacyPoliceAccepted: !privacyPoliceAccepted
        }, { merge: true });

        
    }

    const handleCloseSimpleDialog = () => {
        setIsOpenSimpleDialog(false);
    }

    const deleteProfile = async () => {
        try {
            await deleteDoc(doc(db, "Clients", auth.currentUser.uid));
            const storage = getStorage();
            const desertRef = ref(storage, auth.currentUser.uid);
            if(desertRef){
                await deleteObject(desertRef);
            }
            await deleteUser(auth.currentUser);
            dispatch({
                type: "ADD_TO_BASKET",
                item: [],
            });
            await signOut(auth);
            setIsOpenSimpleDialog(false);
        } catch (er) {
            console.log(er);
            window.confirm('Došlo je do greške prilikom brisanja profila');
        }
    }

    const handleChange = (e) => {
        const language = e.target.value;
        setLanguage(language);
        language === 'English' ? logEvent(analytics, 'change_language_to_Serbian') : logEvent(analytics, 'change_language_to_English')
    };

    return (
        <div className='PrivacyPoliceContainer'>
            <Header name={basket[0]?.name} surname={basket[0]?.surname} />
            
            <main className='PrivacyPolice'>
                <select
                    className="DropdownPP"
                    value = {language} // You need to pass the current type as a prop to set the value of the select element
                    onChange={handleChange}
                ><option value="english">
                        English
                    </option>
                    <option value="serbian">
                        Srpski
                    </option>
                </select>
                
                {auth.currentUser ?
                    <div className='privacyPoliceDiv'>
                        <label htmlFor="privacyPolicyCheckBox">Prihvatam politiku privatnosti</label>
                        <input
                            id="privacyPolicyCheckBox"
                            name="newsletter"
                            type="checkbox"
                            className='privacyPolicyCheckBox'
                            onChange={handlePrivacyPoliceCheckBoxClick}
                            value={privacyPoliceAccepted}
                            checked={privacyPoliceAccepted}
                        />
                        <button
                            className='PrivacyPoliceButton'
                            onClick={() => setIsOpenSimpleDialog(true)}
                            aria-label="Delete Profile"
                        >
                            Obriši svoj profil
                        </button>                    </div>
                    :
                    null}
                <br />
                {language === 'serbian' ? <><h1 id="privacy-policy">Politika privatnosti</h1>
                <br/>
<p>Poslednji put ažurirano: 10. juna 2024. godine</p><br/>
<p>Ova Politika privatnosti opisuje našu politiku i procedure o prikupljanju, korišćenju i otkrivanju Vaših informacija kada koristite uslugu i informiše Vas o Vašim pravima u vezi sa privatnošću i tome kako Vas zakon štiti.</p>
                <p>Mi koristimo Vaše lične podatke za pružanje i poboljšanje usluge. Korišćenjem usluge slažete se sa prikupljanjem i korišćenjem informacija u skladu sa ovom Politikom privatnosti.</p>
                <br />
                <h2 id="tumačenje-i-definicije">Tumačenje i definicije</h2>
                <br />
                <h3 id="tumačenje">Tumačenje</h3>
                <br />  
                <p>Pojedini izrazi u ovoj Politici privatnosti imaju značenja definisana pod sledećim uslovima i imaće ista značenja bez obzira na to da li se pojavljuju u jednini ili množini.</p>
                <br />
                <h3 id="definicije">Definicije</h3>
                <br />
                <p>Za potrebe ove Politike privatnosti:</p>
                <br />
<ol>
<li><p>nalog označava jedinstveni nalog kreiran za Vas kako biste pristupili našoj usluzi ili delovima naše usluge;</p>
                    </li>
                    <br />
<li><p>povezano preduzeće znači entitet koji kontroliše, koji je pod kontrolom ili koji je pod zajedničkom kontrolom sa kompanijom, gde ,,kontrola&quot; znači vlasništvo nad 50% ili više akcija, udelima u kapitalu ili drugim hartijama od vrednosti koje imaju pravo glasa za izbor direktora ili drugog upravljačkog organa;</p>
                    </li><br />
<li><p>aplikacija se odnosi na Evu, softverski program koji pruža kompanija;</p>
                    </li> <br />
<li><p>kompanija (označena kao ,,kompanija&quot;, ,,mi&quot;, ,,nas&quot; ili ,,naš&quot; u ovom ugovoru) odnosi se na Evu;</p>
                    </li><br />
<li><p>država se odnosi na Srbiju;</p>
                    </li><br />
<li><p>uređaj znači bilo koji uređaj koji može pristupiti usluzi kao što su računar, mobilni telefon ili digitalni tablet;</p>
                    </li><br />
<li><p>lični podaci su bilo koje informacije koje se odnose na identifikovanu osobu ili osobu koja se može identifikovati;</p>
                    </li><br />
<li><p>usluga se odnosi na aplikaciju i veb-sajt;</p>
                    </li><br />
<li><p>provajder usluge znači bilo koje fizičko ili pravno lice koje obrađuje podatke u ime kompanije; odnosi se na kompanije trećih lica ili pojedince angažovane od strane kompanije da olakšaju uslugu, pruže uslugu u ime kompanije, izvršavaju usluge u vezi sa uslugom ili pomognu kompaniji u analiziranju toga kako se usluga koristi;</p>
                    </li><br />
<li><p>podaci o korišćenju se odnose na podatke prikupljene automatski, generisane korišćenjem usluge ili iz same infrastrukture usluge (na primer: trajanje posete stranici);</p>
                    </li><br />
<li><p>Vi označava pojedinca koji pristupa ili koristi uslugu, ili kompaniju, ili drugo pravno lice u ime kog takav pojedinac pristupa ili koristi uslugu.</p>
                    </li><br />
</ol>
                <h2 id="prikupljanje-i-korišćenje-vaših-ličnih-podataka">Prikupljanje i korišćenje Vaših ličnih podataka</h2><br />
                <h3 id="tipovi-prikupljenih-podataka">Tipovi prikupljenih podataka</h3><br />
                <h4 id="lični-podaci">Lični podaci</h4><br />
                <p>Dok koristite našu uslugu, možemo Vas zamoliti da nam pružite određene lične informacije koje mogu biti korišćene da Vas kontaktiramo ili identifikujemo. Lične informacije mogu uključivati, ali nisu ograničene na:</p><br />
<ol>
                    <li>e-mail adresu</li><br />
                    <li>ime i prezime</li><br />
                    <li>korisničko ime ili pseudonim</li><br />
                    <li>ime grada i/ili države</li><br />
                    <li>pol</li><br />
                    <li>fotografije</li><br />
                    <li>informacije o obrazovanju, dodatnoj edukaciji, profesiji i istoriji zapošljavanja</li><br />
                    <li>lične karakteristike</li><br />
                    <li>informacije o evaluaciji pružalaca psihološke podrške</li><br />
                    <li>informacije iz poruka među klijentima</li><br />
                    <li>informacije o zakazanim i otkazanim terminima</li><br />
                    <li>informacije o načinu naplate usluge</li><br />
                    <li>podatke o korišćenju</li><br />
                </ol><br />
                <p>Dodatne napomene</p><br />
<ol>
                    <li>Eva platforma nema nikakav pristup sadržini sesije/savetovanja/razgovora između klijenta i pružaoca psihološke podrške.</li><br />
                    <li>Poruke služe isključivo za dodatno preciziranje oko zakazivanja i otkazivanja termina, a ne tzv. chat sesiji. Sadržini poruka imamo pristup iz bezbednosnih i funkcionalnih razloga.</li><br />
                    <li>Plaćanje usluge pružaocu psihološke podrške vrši se van Eva platforme i nemamo pristup informacijama o klijentovoj platnoj kartici ukoliko je to način plaćanja.</li><br />
                </ol><br />
                <h4 id="podaci-o-korišćenju">Podaci o korišćenju</h4><br />
<p>Podaci o korišćenju se automatski prikupljaju prilikom korišćenja usluge.</p>
<p>Podaci o korišćenju mogu uključivati informacije kao što su Internet protokol adresa Vašeg uređaja (npr. IP adresa), tip pregledača, verzija pregledača, stranice naše usluge koje posetite, vreme i datum Vaše posete, vreme provedeno na tim stranicama, jedinstveni identifikatori uređaja i drugi dijagnostički podaci.</p>
<p>Kada pristupite usluzi putem ili preko mobilnog uređaja, mi možemo automatski prikupiti određene informacije, uključujući, ali ne ograničujući se na tip mobilnog uređaja koji koristite, jedinstveni ID Vašeg mobilnog uređaja, IP adresu Vašeg mobilnog uređaja, Vaš mobilni operativni sistem, tip mobilnog internet pregledača koji koristite, jedinstvene identifikatore uređaja i druge dijagnostičke podatke.</p>
                <p>Takođe možemo prikupiti informacije koje Vaš pregledač šalje kad god posetite našu uslugu ili kada pristupate usluzi putem ili preko mobilnog uređaja.</p><br />
                <h4 id="korišćenje-vaših-ličnih-podataka">Korišćenje Vaših ličnih podataka</h4><br />
                <p>Kompanija može koristiti lične podatke u sledeće svrhe:</p><br />
<ul>
<li><p><strong>da pruži i održava našu uslugu</strong>, uključujući nadgledanje korišćenja naše usluge,</p>
                    </li><br />
<li><p><strong>da upravlja Vašim nalogom</strong>: da upravlja Vašom registracijom kao korisnikom usluge; lični podaci koje pružite mogu Vam dati pristup različitim funkcionalnostima usluge koje su Vam dostupne kao registrovanom korisniku,</p>
                    </li><br />
<li><p><strong>za izvršenje ugovora</strong>: razvoj, poštovanje i preduzimanje ugovora o kupovini za proizvode, stavke ili usluge koje ste kupili ili bilo koji drugi ugovor sa nama putem usluge,</p>
                    </li><br />
<li><p><strong>da Vas kontaktira</strong>: da vas kontaktira putem e-pošte ili drugih ekvivalentnih oblika elektronske komunikacije, kao što su obaveštenja putem aplikacije za mobilne uređaje o ažuriranjima ili informativnim komunikacijama vezanim za funkcionalnosti, proizvode ili ugovorene usluge, uključujući sigurnosna ažuriranja, kada je to neophodno ili razumno za njihovu implementaciju,</p>
                    </li><br />
<li><p><strong>da Vam pruži</strong> vesti, specijalne ponude i opšte informacije o drugim dobrima, uslugama i događajima koje nudimo, a koji su slični onima koje ste već kupili ili o kojima ste se raspitali osim ako se niste odlučili da ne primite takve informacije,</p>
                    </li><br />
<li><p><strong>da upravlja Vašim zahtevima</strong>: da prisustvuje i upravlja Vašim zahtevima upućenim nama,</p>
                    </li><br />
<li><p><strong>za poslovne transfere</strong>: možemo koristiti Vaše informacije za procenu ili sprovođenje spajanja, otuđenja, restrukturiranja, reorganizacije, raspuštanja ili druge prodaje ili prenosa neke ili svih naših imovina, bilo kao tekući posao ili kao deo stečaja, likvidacije ili sličnog postupka, u kojem su lični podaci koje držimo o korisnicima naše usluge među prenetoj imovini,</p>
                    </li><br />
<li><p><strong>za druge svrhe</strong>: možemo koristiti Vaše informacije za druge svrhe, kao što su analiza podataka, identifikovanje trendova upotrebe, određivanje efikasnosti naših promotivnih kampanja i za procenu i poboljšanje naše usluge, proizvoda, usluga, marketinga i Vašeg iskustva.</p>
                    </li><br />
</ul>
<p>Možemo deliti Vaše lične informacije u sledećim situacijama:</p>
                <ul><br />
<li><p><strong>sa provajderima usluga</strong>: možemo deliti Vaše lične informacije sa provajderima usluga kako bismo nadgledali i analizirali korišćenje naše usluge, kao i da bismo Vas kontaktirali,</p>
                    </li><br />
<li><p><strong>za poslovne transfere</strong>: možemo deliti ili preneti Vaše lične informacije u vezi sa ili tokom pregovora o bilo kom spajanju, prodaji imovine kompanije, finansiranju ili sticanju celokupnog ili dela našeg poslovanja drugoj kompaniji,</p>
                    </li><br />
<li><p><strong>sa povezanim preduzećima</strong>: možemo deliti Vaše informacije sa našim povezanim preduzećima u kom slučaju ćemo zahtevati da ta povezana preduzeća poštuju ovu Politiku privatnosti. Povezana preduzeća uključuju našu matičnu kompaniju i bilo koje druge filijale, partnere u zajedničkom ulaganju ili druge kompanije koje kontrolišemo ili koje su pod zajedničkom kontrolom sa nama,</p>
                    </li><br />
<li><p><strong>sa poslovnim partnerima</strong>: možemo deliti Vaše informacije sa našim poslovnim partnerima kako bismo Vam ponudili određene proizvode, usluge ili promocije,</p>
                    </li><br />
<li><p><strong>sa drugim korisnicima</strong>: kada delite lične informacije ili na drugi način interagujete u javnim oblastima sa drugim korisnicima, takve informacije mogu biti viđene od svih korisnika i mogu biti javno distribuirane van (primer: kada pružalac psihološke podrške objavi svoj profil javno na Eva aplikaciju),</p>
                    </li><br />
<li><p><strong>sa Vašim pristankom</strong>: možemo otkriti Vaše lične informacije u bilo koje druge svrhe sa Vašim pristankom.</p>
                    </li><br />
</ul>
                <h3 id="zadržavanje-vaših-ličnih-podataka">Zadržavanje Vaših ličnih podataka</h3><br />
<p>Kompanija će zadržati Vaše lične podatke samo onoliko dugo koliko je to neophodno za svrhe navedene u ovoj Politici privatnosti. Zadržaćemo i koristićemo Vaše lične podatke u meri neophodnoj za poštovanje naših zakonskih obaveza (na primer, ako smo dužni da zadržimo Vaše podatke kako bismo ispunili važeće zakone), rešavanje sporova i sprovođenje naših pravnih sporazuma i politika.</p>
                <p>Kompanija će takođe zadržati podatke o korišćenju za interne analitičke svrhe. Podaci o korišćenju se generalno zadržavaju kraći period osim kada se ti podaci koriste za jačanje sigurnosti ili za poboljšanje funkcionalnosti naše usluge, ili ako smo zakonski obavezni da zadržimo ove podatke na duži vremenski period.</p><br />
                <h4 id="prenos-vaših-ličnih-podataka">Prenos Vaših ličnih podataka</h4><br />
<p>Vaše informacije, uključujući lične podatke, obrađuju se u poslovnim prostorijama kompanije i na bilo kojim drugim mestima gde su stranke uključene u obradu locirane. To znači da se ove informacije mogu preneti i održavati na računarima lociranim izvan Vaše države, provincije, zemlje ili druge vladine jurisdikcije gde se zakoni o zaštiti podataka mogu razlikovati od onih iz Vaše jurisdikcije.</p>
<p>Vaš pristanak na ovu Politiku privatnosti praćen Vašom predajom takvih informacija predstavlja Vaš pristanak na taj prenos.</p>
<p>Kompanija će preduzeti sve razumno neophodne korake da osigura da se Vaši podaci tretiraju sigurno i u skladu sa ovom Politikom privatnosti i da se Vaši lični podaci neće preneti organizaciji ili zemlji osim ako nema adekvatnih kontrola na mestu uključujući sigurnost Vaših podataka i drugih ličnih informacija.</p>
                <br /><h3 id="brisanje-vaših-ličnih-podataka">Brisanje Vaših ličnih podataka</h3><br />
<p>Imate pravo da izbrišete ili zatražite da Vam pomognemo u brisanju ličnih podataka koje smo prikupili o Vama.</p>
<p>Možete ažurirati, izmeniti ili obrisati određene informacije o sebi u bilo koje vreme na svom nalogu, ako ga imate, kao i obrisati sve svoje informacije u odeljku <em>Politike privatnosti</em> tako što ćete kliknuti na dugme <em>Obriši svoj profil</em>.</p>
<p>Imate pravo zatražiti sve svoje lične podatke od kontrolora i preneti ih drugom kontroloru. Takođe nas možete kontaktirati da zatražite pristup, ispravku ili brisanje bilo kojih ličnih informacija koje ste nam pružili. Informacije koje ste izmenili ili obrisali neće biti moguće povratiti. </p>
<p>Međutim, imajte na umu da možemo zadržati određene informacije kada imamo zakonsku obavezu ili zakonsko pravo da to učinimo.</p>
                <br /><h3 id="otkrivanje-vaših-ličnih-podataka">Otkrivanje Vaših ličnih podataka</h3><br />
                <h4 id="poslovne-transakcije">Poslovne transakcije</h4><br />
<p>Ako je kompanija uključena u spajanje, sticanje ili prodaju imovine, Vaši lični podaci mogu biti preneti. Obavestićemo Vas pre nego što Vaši lični podaci budu preneti i postanu predmet druge Politike privatnosti.</p>
                <br /><h3 id="organi-za-sprovođenje-zakona">Organi za sprovođenje zakona</h3><br />
<p>Pod određenim okolnostima kompanija može biti obavezna da otkrije Vaše lične podatke ako je to potrebno zakonom ili kao odgovor na važeće zahteve javnih vlasti (npr. sud ili vladina agencija).</p>
                <br /><h4 id="drugi-zakonski-zahtevi">Drugi zakonski zahtevi</h4><br />
                <p>Kompanija može otkriti Vaše lične podatke u ,,dobroj veri&#39;&#39; ako je takva akcija neophodna da:</p><br />
<ul>
                    <li>poštuje zakonsku obavezu,</li><br />
                    <li>zaštiti i brani prava ili imovinu kompanije,</li><br />
                    <li>spreči ili istraži moguće nepravilnosti u vezi sa uslugom,</li><br />
                    <li>zaštiti ličnu sigurnost korisnika usluge ili javnosti,</li><br />
                    <li>zaštiti sebe od pravne odgovornosti.</li><br />
</ul>
                <h3 id="sigurnost-vaših-ličnih-podataka">Sigurnost vaših ličnih podataka</h3><br />
<p>Sigurnost Vaših ličnih podataka je važna za nas, ali zapamtite da nijedan način prenosa preko interneta ili način elektronskog skladištenja nije 100% siguran. Iako se trudimo da koristimo komercijalno prihvatljive načine za zaštitu Vaših ličnih podataka, ne možemo garantovati njihovu apsolutnu sigurnost.</p>
 
                    <br />
                    <h2 id="googleapiservices">Pravila o korisničkim podacima usluge Google API</h2>
                    <br />
                    <p> Evina upotreba i prenos informacija primljenih od Google API-ja u bilo koju drugu aplikaciju biće u skladu sa <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API uslugama Pravila o korisničkim podacima</a>, uključujući zahteve ograničene upotrebe.</p>
                    <br />             
                    <br /><h2 id="google-analitike">Google analitike</h2><br />
<p>Koristimo Gugl analitike kako je opisano u <a href="https://policies.google.com/technologies/partner-sites?hl=sr-Latn">Kako Gugl koristi informacije sa sajtova ili aplikacija koje koriste naše usluge</a> . </p>
                <br /><h2 id="kolačići-i-tehnologije-praćenja">Kolačići i tehnologije praćenja</h2><br />
                <p>Naša aplikacija koristi kolačiće i slične tehnologije praćenja kako bi pružila bolje korisničko iskustvo i analizirala naš saobraćaj. U nastavku je detaljan opis tipova kolačića koje koristimo i njihove svrhe.</p><br />
                <h4 id="neophodninužni-kolačići">Neophodni/Nužni kolačići</h4><br />
                <p>Ovi kolačići su ključni za osnovno funkcionisanje naše aplikacije i ne mogu se onemogućiti. Oni uključuju:</p><br />
<ul>
                    <li><strong>Instance ID</strong>: Jedinstveni identifikatori za instance instalacije aplikacije.</li><br />
                    <li><strong>Device ID</strong>: Jedinstveni identifikatori za uređaje na kojima je aplikacija instalirana.</li><br />
</ul>
                <h4 id="kolačići-za-performanseanalitiku">Kolačići za performanse/analitiku</h4><br />
<p>Ovi kolačići nam pomažu da razumemo kako korisnici interaguju sa našom aplikacijom prikupljanjem i izveštavanjem informacija anonimno. Ove informacije nam pomažu da poboljšamo korisničko iskustvo. Kolačići za performanse/analitiku koje koristimo uključuju:</p>
                <br /><ul>
                    <li><strong>_ga</strong>: Koristi se za razlikovanje korisnika. Ovaj kolačić ističe nakon 2 godine.</li><br />
                    <li><strong>_gid</strong>: Koristi se za razlikovanje korisnika. Ovaj kolačić ističe nakon 24 sata.</li><br />
                    <li><strong><em>gac</em></strong>: Sadrži informacije vezane za kampanju i koristi se za povezivanje Firebase-a i Google Ads naloga.</li><br />
                    <li><strong>_gaexp</strong>: Koristi se za određivanje uključivanja korisnika u eksperiment i trajanja eksperimenata u kojima je korisnik učestvovao.</li><br />
                    <li><strong><em>utm</em></strong>: Različiti kolačići kao što su <code>_utmz</code>, <code>_utma</code>, <code>_utmb</code> i <code>_utmc</code> prate parametre kampanje kao što su izvor, medijum i naziv kampanje.</li><br />
</ul>
                <h4 id="funkcionalni-kolačići">Funkcionalni kolačići</h4><br />
                <p>Ovi kolačići omogućavaju našoj aplikaciji da pruži poboljšanu funkcionalnost i personalizaciju. Iako Firebase ne postavlja specifične funkcionalne kolačiće po defaultu, prilagođene implementacije mogu uključivati takve kolačiće za poboljšanje korisničkog iskustva.</p><br />
                <h4 id="kolačići-za-ciljanjereklame">Kolačići za ciljanje/reklame</h4><br />
                <p>Ovi kolačići se koriste za isporuku relevantnih reklama korisnicima i praćenje efikasnosti naših reklamnih kampanja. Oni uključuju:</p><br />
<ul>
                    <li><strong>_fbc</strong>: Koristi se za čuvanje Facebook Click ID kada se koriste Facebook reklame.</li><br />
                    <li><strong>_fbp</strong>: Koristi ga Facebook za isporuku serije reklamnih proizvoda kao što je real-time bidding od trećih strana oglašivača.</li><br />
</ul>
                <h4 id="kolačići-za-društvene-mreže">Kolačići za društvene mreže</h4><br />
<p>Iako naša aplikacija sama po sebi ne postavlja specifične kolačiće za društvene mreže, može interagovati sa kolačićima društvenih mreža putem integracija sa platformama društvenih mreža za praćenje deljenja sadržaja i reklamne svrhe.</p>
                <br /><h3 id="upravljanje-kolačićima">Upravljanje kolačićima</h3><br />
<p>Možete kontrolisati i upravljati kolačićima putem postavki svog pretraživača. Imajte na umu da onemogućavanje određenih kolačića može uticati na funkcionalnost naše aplikacije. Za više informacija o upravljanju kolačićima, posetite odeljak za pomoć na svom internet pretraživaču.</p>
                <br /><h3 id="saglasnost">Saglasnost</h3><br />
<p>Korišćenjem naše aplikacije, pristajete na korišćenje kolačića kao što je opisano u ovoj politici. Ako se ne slažete sa korišćenjem kolačića, molimo Vas da ih onemogućite prema gore navedenim uputstvima ili da se uzdržite od korišćenja naše aplikacije.</p>
                <br /><h2 id="pristup-google-kalendaru-i-google-meet-link">Pristup Google kalendaru i Google Meet link</h2>
                <br /><p>Naša aplikacija Eva omogućava video sesije između pružalaca psihološke podrške i njihovih klijenata putem Google Meet-a. Kako bismo olakšali zakazivanje ovih sesija, naša aplikacija zahteva pristup Google kalendaru korisnika. Evo kako ovaj proces funkcioniše i šta podrazumeva:</p>
                <br /><h4 id="kojim-podacima-pristupamo">Kojim podacima pristupamo?</h4>
<ul>
                    <li><strong>Google kalendar</strong>: Tražimo pristup Vašem Google kalendaru kako bismo upravljali i zakazivali termine za video sesije. Ovo uključuje pregled i kreiranje događaja na Vašem kalendaru.</li><br />
                    <li><strong>Google Meet linkovi</strong>: Generišemo Google Meet linkove za zakazane video sesije i ugrađujemo ih u događaje kalendara radi lakšeg pristupa.</li><br />
</ul>
                <h4 id="zašto-nam-potrebni-ovi-podaci">Zašto nam potrebni ovi podaci?</h4><br />
<ul>
                    <li><strong>Zakazivanje sesija</strong>: Pristupanjem Vašem Google kalendaru možemo Vam pomoći da efikasno zakažete sesije, kao i da Vam pošaljemo podsetnike.</li><br />
                    <li><strong>Upravljanje terminima</strong>: Možemo automatski ažurirati Vaš kalendar sa detaljima sesije, čime Vam olakšavamo upravljanje terminima i održavanje organizacije.</li><br />
                    <li><strong>Generisanje Google Meet linkova</strong>: Koristimo Google Meet za bezbedno održavanje video sesija. Integracijom sa Google kalendarom, osiguravamo da svaka sesija ima jedinstven i privatan link za sastanak.</li><br />
</ul>
                <h4 id="kako-koristimo-ove-podatke">Kako koristimo ove podatke?</h4><br />
<ul>
                    <li><strong>Kreiranje i ažuriranje događaja</strong>: Kada zakažete sesiju, kreiramo događaj u Vašem Google kalendaru sa detaljima sesije i Google Meet linkom.</li><br />
                    <li><strong>Podsetnici i obaveštenja</strong>: Možemo Vam slati podsetnike i obaveštenja o predstojećim sesijama putem e-pošte ili obaveštenja aplikacije koristeći informacije iz Vašeg Google kalendara.</li><br />
                    <li><strong>Privatnost i sigurnost podataka</strong>: Poštujemo Vašu privatnost i osiguravamo da se Vaši podaci koriste isključivo za gore opisane svrhe. Ne delimo Vaše podatke iz kalendara sa trećim stranama bez Vašeg pristanka.</li><br />
</ul>
                <h3 id="saglasnost-korisnika">Saglasnost Korisnika</h3><br />
<p>Korišćenjem naše Eva aplikacije i davanjem pristupa svom Google kalendaru, pristajete na prikupljanje i korišćenje svojih podataka kako je opisano u ovom delu. Ako se ne slažete sa ovim uslovima, molimo Vas da ne dozvolite pristup svom Google kalendaru ili da ne koristite funkcije zakazivanja naše aplikacije.</p>
                <br /><h2 id="privatnost-dece">Privatnost dece</h2><br />
<p>Naša usluga nije namenjena nikome mlađem od 16 godina. Ne prikupljamo svesno lične informacije od bilo kog mlađeg od 16 godina. Ako ste roditelj ili staratelj i svesni ste da nam je Vaše dete pružilo lične podatke, molimo Vas da nas kontaktirate. Ako saznamo da smo prikupili lične podatke od bilo koga mlađeg od 16 godina bez verifikacije roditeljskog pristanka, preduzećemo korake da uklonimo te informacije sa naših servera.</p>
<p>Ako nam je potreban pristanak kao pravni osnov za obradu Vaših informacija i Vaša država zahteva pristanak roditelja, možemo zahtevati pristanak Vašeg roditelja pre nego što prikupimo i koristimo te informacije.</p>
                <br /><h2 id="linkovi-ka-drugim-veb-sajtovima">Linkovi ka drugim veb-sajtovima</h2><br />
<p>Naša usluga može sadržati linkove ka drugim veb sajtovima koji nisu u našoj operaciji. Ako kliknete na link treće strane, bićete usmereni na sajt te treće strane. Snažno Vam savetujemo da pregledate Politiku privatnosti svakog sajta koji posetite.</p>
<p>Nemamo kontrolu nad sadržajem niti preuzimamo bilo kakvu odgovornost za sadržaj, politike privatnosti ili prakse bilo kog veb-sajta ili usluge treće strane.</p>
                <br /><h2 id="izmene-ove-politike-privatnosti">Izmene ove Politike privatnosti</h2><br />
                <br /><p>Možemo ažurirati našu Politiku privatnosti povremeno. </p><br />
<p>Obavestićemo registrovane korisnike putem e-pošte i/ili istaknutog obaveštenja na našoj usluzi pre nego što izmena postane efektivna i ažuriraćemo datum ,,Poslednje ažurirano&quot; na vrhu ove Politike privatnosti.</p>
<p>Svakako Vam savetujemo da periodično pregledate ovu Politiku privatnosti zbog bilo kakvih izmena. Izmene ove Politike privatnosti postaju efektivne nakon slanja obaveštenja putem e-pošte registrovanim korisnicima i nakon objavljivanja na ovoj stranici.</p>
                <br /><h2 id="kontaktirajte-nas">Kontaktirajte nas</h2><br />
<p>Cenimo Vaše poverenje i poštujemo privatnost Vaših ličnih podataka. Ako imate bilo kakva pitanja, prigovore ili žalbe o ovoj Politici privatnosti, možete nas kontaktirati:</p>
                <br /><ul>
<li>putem e-maila: <a href="mailto:&#x65;&#x76;&#x61;&#119;&#x65;&#108;&#x6c;&#x62;&#x65;&#x69;&#x6e;&#x67;&#x40;&#x67;&#109;&#97;&#105;&#108;&#46;&#x63;&#111;&#109;">&#x65;&#x76;&#x61;&#119;&#x65;&#108;&#x6c;&#x62;&#x65;&#x69;&#x6e;&#x67;&#x40;&#x67;&#109;&#97;&#105;&#108;&#46;&#x63;&#111;&#109;</a> .</li>
                </ul><br />
<p>Sa druge strane, ukoliko niste zadovoljni time kako upravljamo Vašim ličnim podacima, možete se obratiti Upravi za zaštitu podataka.</p>
                </>
                    : <> <h1 id="politika-privatnosti">Privacy Policy</h1><br/>
                        <p><strong>Last updated: June 10, 2024</strong></p>
<br/>
<p>This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use our service. It also informs you about your privacy rights and how the law protects you.</p>
<br/>
<p>We use your personal data to provide and improve the service. By using the service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
<br/>
<h2 id="interpretationanddefinitions">Interpretation and Definitions</h2>
<br/>
<h3 id="interpretation">Interpretation</h3>
<br/>
<p>Certain expressions have meanings defined under the following conditions. These definitions shall have the same meaning regardless of whether they appear in singular or plural form.</p>
<br/>
<h3 id="definitions">Definitions</h3>
<br/>
<p>For the purposes of this Privacy Policy:</p>
<br/>
<ul>
  <li><p><strong>Account</strong> means a unique account created for you to access our service or parts of our service.</p></li>
  <br/>
  <li><p><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a company, where "control" means ownership of 50% or more of the shares, equity interests, or other securities entitled to vote for election of directors or other managing authorities.</p></li>
  <br/>
  <li><p><strong>Application</strong> refers to Eva, the software program provided by the company.</p></li>
  <br/>
  <li><p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Eva.</p></li>
  <br/>
  <li><p><strong>Country</strong> refers to Serbia.</p></li>
  <br/>
  <li><p><strong>Device</strong> means any device that can access the service such as a computer, a cellphone, or a digital tablet.</p></li>
  <br/>
  <li><p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p></li>
  <br/>
  <li><p><strong>Service</strong> refers to the Application and Website.</p></li>
  <br/>
  <li><p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the service, provide the service on behalf of the Company, perform services related to the service, or assist the Company in analyzing how the service is used.</p></li>
  <br/>
  <li><p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (for example, the duration of a page visit).</p></li>
  <br/>
  <li><p><strong>You</strong> means the individual accessing or using the service, or the company, or other legal entity on behalf of which such individual is accessing or using the service, as applicable.</p></li>
</ul>
<br/>
<h2 id="collectingandusingyourpersonaldata">Collecting and Using Your Personal Data</h2>
<br/>
<h3 id="typesofdatacollected">Types of Data Collected</h3>
<br/>
<h4 id="personaldata">Personal Data</h4>
<br/>
<p>While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
<br/>
<ul>
  <li><p>Email address</p></li>
  <br/>
  <li><p>First name and last name</p></li>
  <br/>
  <li><p>Username or pseudonym</p></li>
  <br/>
  <li><p>City and/or state</p></li>
  <br/>
  <li><p>Gender</p></li>
  <br/>
  <li><p>Photos</p></li>
  <br/>
  <li><p>Education, additional training, profession, and employment history</p></li>
  <br/>
  <li><p>Personal characteristics</p></li>
  <br/>
  <li><p>Information about the evaluation of psychological support providers</p></li>
  <br/>
  <li><p>Information from messages between clients</p></li>
  <br/>
  <li><p>Information about scheduled and canceled appointments</p></li>
  <br/>
  <li><p>Information about the billing method for the service</p></li>
  <br/>
  <li><p>Usage Data</p></li>
</ul>
<br/>
<p><strong>Additional Notes</strong></p>
<br/>
<p>The Eva platform does not have any access to the content of the sessions/counseling/conversations between the client and the psychological support provider.</p>
<br/>
<p>Messages are used solely for additional clarification about scheduling and canceling appointments, not for chat sessions. We have access to the content of messages for security and functional reasons.</p>
<br/>
<p>Payment for the psychological support service is made outside the Eva platform, and we do not have access to information about the client's payment card if this is the method of payment.</p>
<br/>
<h4 id="usagedata">Usage Data</h4>
<br/>
<p>Usage Data is collected automatically when using the Service.</p>
<br/>
<p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
<br/>
<p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
<br/>
<p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
<br/>
<h4 id="useofyourpersonaldata">Use of Your Personal Data</h4>
<br/>
<p>The Company may use personal data for the following purposes:</p>
<br/>
<ul>
  <li>To provide and maintain our service, including monitoring the usage of our service.</li>
  <br/>
  <li>To manage your account: to manage your registration as a user of the service. The personal data you provide can give you access to different functionalities of the service that are available to you as a registered user.</li>
  <br/>
  <li>For the performance of a contract: the development, compliance, and undertaking of the purchase contract for products, items, or services you have purchased or any other contract with us through the service.</li>
  <br/>
  <li>To contact you: to contact you by email or other equivalent forms of electronic communication, such as mobile application push notifications regarding updates or informative communications related to functionalities, products, or contracted services, including security updates, when necessary or reasonable for their implementation.</li>
  <br/>
  <li>To provide you with news, special offers, and general information about other goods, services, and events that we offer similar to those that you have already purchased or inquired about unless you have opted not to receive such information.</li>
  <br/>
  <li>To manage your requests: to attend and manage your requests to us.</li>
  <br/>
  <li>For business transfers: we may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by us about our service users is among the assets transferred.</li>
  <br/>
  <li>For other purposes: we may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our service, products, services, marketing, and your experience.</li>
</ul>
<br/>
<p>We may share your personal information in the following situations:</p>
<br/>
<ul>
  <li>With service providers: we may share your personal information with service providers to monitor and analyze the use of our service, as well as to contact you.</li>
  <br/>
  <li>For business transfers: we may share or transfer your personal information in connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
  <br/>
  <li>With affiliates: we may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
  <br/>
  <li>With business partners: we may share your information with our business partners to offer you certain products, services, or promotions.</li>
  <br/>
  <li>With other users: when you share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside (for example, when a psychological support provider posts their profile publicly on the Eva application).</li>
  <br/>
  <li>With your consent: we may disclose your personal information for any other purpose with your consent.</li>
</ul>
<br/>
<h3 id="retentionofyourpersonaldata">Retention of Your Personal Data</h3>
<br/>
<p>The Company will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
<br/>
<p>The Company will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period, except when this data is used to strengthen the security or improve the functionality of our service, or if we are legally obligated to retain this data for longer time periods.</p>
<br/>
<h4 id="transferofyourpersonaldata">Transfer of Your Personal Data</h4>
<br/>
<p>Your information, including personal data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
<br/>
<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
<br/>
<p>The Company will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your personal data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
<br/>
<h4 id="deletingyourpersonaldata">Deleting Your Personal Data</h4>
<br/>
<p>You have the right to delete or request that we assist in deleting the personal data that we have collected about you.</p>
<br/>
<p>You may update, amend, or delete your information at any time by logging into your account if you have one, and by visiting the settings section that allows you to manage your personal information. You can also delete all your information in the Privacy Policy section by clicking the "Delete your profile" button.</p>
<br/>
<p>You have the right to request all your personal data from the controller and transfer them to another controller. You can also contact us to request access, correction, or deletion of any personal information that you have provided to us. The information that you have modified or deleted may not be recoverable.</p>
<br/>
<p>However, please note that we may retain certain information when we have a legal obligation or lawful right to do so.</p>
<br/>
<h3 id="disclosureofyourpersonaldata">Disclosure of Your Personal Data</h3>
<br/>
<p>If the company is involved in a merger, acquisition, or sale of assets, your personal data may be transferred. We will notify you before your personal data is transferred and becomes subject to a different Privacy Policy.</p>
<br/>
<h3 id="lawenforcement">Law Enforcement</h3>
<br/>
<p>Under certain circumstances, the company may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p>
<br/>
<h3 id="otherlegalrequirements">Other Legal Requirements</h3>
<br/>
<p>The company may disclose your personal data in the good faith belief that such action is necessary to:</p>
<br/>
<ul>
  <li><p>Comply with a legal obligation,</p></li>
  <br/>
  <li><p>Protect and defend the rights or property of the company,</p></li>
  <br/>
  <li><p>Prevent or investigate possible wrongdoing in connection with the service,</p></li>
  <br/>
  <li><p>Protect the personal safety of users of the service or the public,</p></li>
  <br/>
  <li><p>Protect against legal liability.</p></li>
</ul>
<br/>
<h3 id="securityofyourpersonaldata">Security of Your Personal Data</h3>
<br/>
<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
<br />
<h2 id="googleapiservices">Google API Services User Data Policy</h2>
                        <br />
                        <p> Eva's use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>

<br />
<h2 id="googleanalytics">Google Analytics</h2>
<br/>
<p>We use Google Analytics as described in ''<a href="https://policies.google.com/technologies/partner-sites?hl=en-US">How Google uses information from sites or apps that use our services</a>".</p>
<br/>
<h2 id="cookiesandtrackingtechnologies">Cookies and Tracking Technologies</h2>
<br/>
<p>Our application uses cookies and similar tracking technologies to provide a better user experience and analyze our traffic. Below is a detailed description of the types of cookies we use and their purposes.</p>
<br/>
<h4 id="necessarycookies">Necessary Cookies</h4>
<br/>
<p>These cookies are essential for the basic functioning of our application and cannot be disabled. They include:</p>
<br/>
<ul>
  <li><p><strong>Instance ID:</strong> Unique identifiers for instance installations of the application.</p></li>
  <br/>
  <li><p><strong>Device ID:</strong> Unique identifiers for devices on which the application is installed.</p></li>
</ul>
<br/>
<h4 id="performanceanalyticscookies">Performance/Analytics Cookies</h4>
<br/>
<p>These cookies help us understand how users interact with our application by collecting and reporting information anonymously. This information helps us improve the user experience. The performance/analytics cookies we use include:</p>
<br/>
<ul>
  <li><p><strong>_ga:</strong> Used to distinguish users. This cookie expires after 2 years.</p></li>
  <br/>
  <li><p><strong>_gid:</strong> Used to distinguish users. This cookie expires after 24 hours.</p></li>
  <br/>
  <li><p><strong>gac:</strong> Contains campaign-related information and is used to link Firebase and Google Ads accounts.</p></li>
  <br/>
  <li><p><strong>_gaexp:</strong> Used to determine a user's inclusion in an experiment and the duration of experiments in which a user has been included.</p></li>
  <br/>
  <li><p><strong>utm:</strong> Various cookies such as _utmz, _utma, _utmb, and _utmc track campaign parameters such as source, medium, and campaign name.</p></li>
</ul>
<br/>
<h4 id="functionalcookies">Functional Cookies</h4>
<br/>
<p>These cookies allow our application to provide enhanced functionality and personalization. Although Firebase does not set specific functional cookies by default, custom implementations may include such cookies to improve the user experience.</p>
<br/>
<h4 id="targetingadvertisingcookies">Targeting/Advertising Cookies</h4>
<br/>
<p>These cookies are used to deliver relevant advertisements to users and track the effectiveness of our advertising campaigns. They include:</p>
<br/>
<ul>
  <li><p><strong>_fbc:</strong> Used to store Facebook Click ID when Facebook ads are used.</p></li>
  <br/>
  <li><p><strong>_fbp:</strong> Used by Facebook to deliver a series of advertisement products such as real-time bidding from third-party advertisers.</p></li>
</ul>
<br/>
<h4 id="socialmediacookies">Social Media Cookies</h4>
<br/>
<p>While our application does not itself set specific social media cookies, it may interact with social media platform cookies through integrations for content sharing and advertising purposes.</p>
<br/>
<h3 id="managingcookies">Managing Cookies</h3>
<br/>
<p>You can control and manage cookies through your browser settings. Please note that disabling certain cookies may affect the functionality of our application. For more information on managing cookies, visit the help section of your internet browser.</p>
<br/>
<h3 id="consent">Consent</h3>
<br/>
<p>By using our application, you consent to the use of cookies as described in this policy. If you do not agree with the use of cookies, please disable them according to the instructions above or refrain from using our application.</p>
<br/>
<h2 id="accesstogooglecalendarandgooglemeetlinks">Access to Google Calendar and Google Meet Links</h2>
<br/>
<p>Our Eva application enables video sessions between psychological support providers and their clients via Google Meet. To facilitate scheduling these sessions, our application requires access to the user's Google Calendar. Here's how this process works and what it involves:</p>
<br/>
<h4 id="whatdatadoweaccess">What Data Do We Access?</h4>
<br/>
<ul>
  <li><p><strong>Google Calendar:</strong> We request access to your Google Calendar to manage and schedule appointments for video sessions. This includes viewing and creating events on your calendar.</p></li>
  <br/>
  <li><p><strong>Google Meet Links:</strong> We generate Google Meet links for scheduled video sessions and embed them into calendar events for easy access.</p></li>
</ul>
<br/>
<h4 id="whydoweneedthisdata">Why Do We Need This Data?</h4>
<br/>
<ul>
  <li><p><strong>Scheduling Sessions:</strong> By accessing your Google Calendar, we can help you efficiently schedule sessions and send reminders.</p></li>
  <br/>
  <li><p><strong>Managing Appointments:</strong> We can automatically update your calendar with session details, making it easier for you to manage appointments and stay organized.</p></li>
  <br/>
  <li><p><strong>Generating Google Meet Links:</strong> We use Google Meet for secure video sessions. Integrating with Google Calendar ensures each session has a unique and private meeting link.</p></li>
</ul>
<br/>
<h4 id="howdoweusethisdata">How Do We Use This Data?</h4>
<br/>
<ul>
  <li><p><strong>Creating and Updating Events:</strong> When you schedule a session, we create an event in your Google Calendar with session details and a Google Meet link.</p></li>
  <br/>
  <li><p><strong>Reminders and Notifications:</strong> We can send you reminders and notifications about upcoming sessions via email or application notifications using information from your Google Calendar.</p></li>
  <br/>
  <li><p><strong>Privacy and Data Security:</strong> We respect your privacy and ensure your data is used solely for the purposes described above. We do not share your calendar data with third parties without your consent.</p></li>
</ul>
<br/>
<h3 id="userconsent">User Consent</h3>
<br/>
<p>By using our Eva application and granting access to your Google Calendar, you consent to the collection and use of your data as described in this section. If you do not agree to these terms, please do not allow access to your Google Calendar or use the scheduling features of our application.</p>
<br/>
<h2 id="childrensprivacy">Children's Privacy</h2>
<br/>
<p>Our Service does not address anyone under the age of 16. We do not knowingly collect personally identifiable information from anyone under the age of 16. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 16 without verification of parental consent, We take steps to remove that information from Our servers.</p>
<br/>
<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
<br/>
<h2 id="linkstootherwebsites">Links to Other Websites</h2>
<br/>
<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
<br/>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
<br/>
<h2 id="changestothisprivacypolicy">Changes to This Privacy Policy</h2>
<br/>
<p>We may update our Privacy Policy from time to time.</p>
<br/>
<p>We will notify registered users via email and/or a prominent notice on our service before the change becomes effective and update the "Last Updated" date at the top of this Privacy Policy.</p>
<br/>
<p>We advise you to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective after being sent via email to registered users and posted on this page.</p>
<br/>
<h2 id="contactus">Contact Us</h2>
<br/>
<p>We value your trust and respect the privacy of your personal data. If you have any questions, concerns, or complaints about this Privacy Policy, you can contact us:</p>
<br/>
<p>via email: evawellbeing@gmail.com.</p>
<br/>
<p>Additionally, if you are not satisfied with how we handle your personal data, you can contact the Data Protection Authority.</p>
</>}
            </main>
            
            <SimpleDialog isOpenSimpleDialog={isOpenSimpleDialog} handleCloseSimpleDialog={handleCloseSimpleDialog}>
                <p style={{ fontSize: '0.9rem', color: 'hsla(0, 0%, 20%, 1)', fontWeight: 400, }}>Ukoliko obrišeš svoj profil svi tvoji podaci koje si ostavio na EVA aplikaciji će biti <b>trajno obrisani.</b></p>
                <br/>
                <p onClick={deleteProfile}
                    className="hoverablePrivacyPoliceButton" style={{
                    marginTop: "0.5rem",
                    fontSize: "0.9rem",
                    color: "hsla(174, 40%, 63%, 1)",
                    borderBottom: "0.1rem solid hsla(0, 0%, 85%, 1)",
                    paddingBottom: "0.3rem",
                    cursor: "pointer",
                    display: 'flex',
                    alignItems: 'center', // Aligns the SVG icon with the text
                }}>
                    
                    Obriši svoj profil
                </p>
                <></>
            </SimpleDialog>
        </div>
    );
}

export default PrivacyPolice;
