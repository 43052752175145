import './ClientSignUp.css';
import { Link } from "react-router-dom";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase-config';

const ClientSignUp = ({ privacyPoliceAccepted, setPrivacyPoliceAccepted, showPassword, showPasswordImage, hidePasswordImage, togglePasswordVisibility, email, setEmail, password, setPassword, name, setName, surname, setSurname, termsAndConditionAccepted, setTermsAndConditionAccepted}) => {
return (
<div className = 'InputsDiv2'>
        <input className='Input' placeholder="ime" value={name} onChange={(e) => setName(e.target.value)} onFocus={() => logEvent(analytics, 'name_input_focused', { type_selected: 'client' })} />
        <input className='Input' placeholder="prezime" value={surname} onChange={(e) => setSurname(e.target.value)} onFocus={() => logEvent(analytics, 'surname_input_focused', { type_selected: 'client' })} />
        <input className='Input' type='email' placeholder="e-mail adresa" value={email} onChange={(e) => setEmail(e.target.value)} onFocus={() => logEvent(analytics, 'email_input_focused', { type_selected: 'client' })} />
<div style={{display: 'flex' }} >
            <input className='Input' type={showPassword ? "text" : "password"} placeholder="lozinka" autocomplete="new-password" onChange={(e) => setPassword(e.target.value)} onFocus={() => logEvent(analytics, 'password_input_focused', { type_selected: 'client' })} />
          <img style={{position:'relative', right:'2rem'}} width="20rem" src={showPassword ? showPasswordImage : hidePasswordImage} className='passwordButton' onClick={togglePasswordVisibility}/>
        </div>
        <label className='privacyPolicyLabel'>
            Prihvatam <Link to={'../privacy-policy'} onClick={() => { logEvent(analytics, 'go_to_privacy_police', { type_selected: 'client' })  }}>politiku privatnosti</Link> &nbsp;
            <input
                name="newsletter"
                type="checkbox"
                className='privacyPolicyCheckBox'
                onChange={() => { privacyPoliceAccepted ? logEvent(analytics, 'privacy_police_unchecked_registration', { type_selected: 'client' }) : logEvent(analytics, 'privacy_police_checked_registration', { type_selected: 'client' }); setPrivacyPoliceAccepted(prev => !prev)}}
                value={privacyPoliceAccepted}
            />
        </label>
        <label className='privacyPolicyLabel'>
            Prihvatam <Link to={'../terms-and-conditions'} onClick={() => { logEvent(analytics, 'go_to_terms_and_conditions', { type_selected: 'client' }) }}>uslove korišćenja</Link> &nbsp;&nbsp;
            <input
                name="newsletter"
                type="checkbox"
                className='privacyPolicyCheckBox'
                onChange={() => { termsAndConditionAccepted ? logEvent(analytics, 'terms_and_conditions_unchecked_registration', { type_selected: 'client' }) : logEvent(analytics, 'terms_and_conditions_checked_registration', { type_selected: 'client' }); setTermsAndConditionAccepted(prev => !prev);}}
                value={termsAndConditionAccepted}
            />
        </label>
</div>
 )}
export default ClientSignUp;