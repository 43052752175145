import "./Hours.css"
import { useState } from "react";

function Hours(props) {

    function CreateHoursUI() {
       return props.hoursArray?.map(hour => {
            
           return (
             <div className='Hours' onClick={props.setCheckBox}>
                <div className='CheckBoxA'  style={props.style}  >{"\u2713"}</div>
                <div className='HoursNumber'>{hour}</div>
             </div>
           );
        });
      }
    
    return (
        
          <div id='HoursContainer1'  style={{width: props.width}} className='HoursContainer'>{CreateHoursUI()}</div>
        
    );
}

export default Hours;