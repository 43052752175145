import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase-config";

const PopUp2 = (props) => {
  const { isOpen2, handleClose2, description, setDescription, setIsOpen2, basket, buttonLoading } = props;

  const handleChange = (setter) => (e) => setter(e.target.value);
  

  const handleCancel = () => {
    setDescription(basket[0].description);
    setIsOpen2(false);
    logEvent(analytics,'close_short_description_dialog')
  };

  return isOpen2 ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.7)',
      }}
    >
      <div
        style={{
          padding: '20px',
          backgroundColor: 'hsla(185, 33%, 40%, 1)',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative', // Added to position the 'x' button
        }}
      >
        {/* 'X' button to close the dialog without saving */}
        <button
          onClick={handleCancel}
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            background: 'transparent',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: '#fff',
          }}
        >
          ×
        </button>

        <textarea
          maxLength={130}
          className="PopUpTextarea"
          placeholder="Dodaj svoj kratak opis (max: 130 karaktera)"
          rows={5}
          style={{ resize: 'none', marginTop: '20px' }}
          value={description}
          onChange={handleChange(setDescription)}
        />
        <button
          className="PopUpButton2"
          onClick={handleClose2}
          style={!buttonLoading ? { alignSelf: 'flex-end', marginTop: '10px', borderRadius: '0.3rem', color: 'black', backgroundColor: 'aquamarine', cursor: 'pointer' } : { alignSelf: 'flex-end', marginTop: '10px', borderRadius: '0.3rem', color: 'black', backgroundColor: 'rgba(127, 255, 212, 0.5)', cursor: 'wait',  }}
        >
          Snimi
        </button>
      </div>
    </div>
  ) : null;
};

export default PopUp2;
