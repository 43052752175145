import './Footer.css'
import { ReactComponent as LogoSvg2 } from '../../SVGs/Logo2.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase-config';

const Footer = () => {
    return (
        <footer className='Footer' role="contentinfo">
            <LogoSvg2 className='LogoFooter'></LogoSvg2>
            <div className='icons-div'>
                <a href="https://www.instagram.com/evawellbeing/" aria-label="Instagram" target="_blank" rel="noopener noreferrer" className='instagram-icon' onClick={()=> logEvent(analytics,'go_to_instagram')}>
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="mailto:evawellbeing@gmail.com" aria-label="Gmail" className='gmail-icon' onClick={() => logEvent(analytics, 'go_to_gmail')}>
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                </a>

                <a href="https://evawellbeing.org/" aria-label="Website" target="_blank" rel="noopener noreferrer" className='website-icon' onClick={() => logEvent(analytics, 'go_to_website')}>
                    <FontAwesomeIcon icon={faGlobe} size="2x" />
                </a>
            </div>    
            <div className='legal' aria-label="Legal">
                <a href="https://evawellbeing.app/terms-and-conditions/" aria-label="Terms and Conditions" className='FooterA' onClick={() => logEvent(analytics, 'go_to_terms_and_condition')}>Terms and Conditions</a>
                <a href="https://evawellbeing.app/privacy-policy/" aria-label="Privacy Policy" className='FooterA' onClick={() => logEvent(analytics, 'go_to_privacy_police')}>Privacy policy</a>
            </div>
        </footer>
    )
}

export default Footer;