import { logEvent } from "firebase/analytics";


const SimpleDialog = ({ isOpenSimpleDialog, handleCloseSimpleDialog, children}) => {
  

  return isOpenSimpleDialog ? (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
    }}>
      <div style={{
        padding: '10px',
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: '5px',
        width: '50%',
        maxWidth: '300px', // Smaller width
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        maxHeight: '50%', // Smaller height
      }}>
        <p style={{
          marginRight: '1rem'
        }}>{children}</p>
        <button onClick={handleCloseSimpleDialog} style={{
          marginTop: '10px',
          alignSelf: 'center', // Center button
          padding: '5px 10px',
          backgroundColor: '#5ea9a1',
          color: '#fff',
          border: 'none',
          borderRadius: '3px',
          cursor: 'pointer',
        }}>
          Izađi
        </button>
        <button onClick={handleCloseSimpleDialog} style={{
          position: 'absolute',
          top: '5px', // Closer to the edge
          right: '5px', // Closer to the edge
          padding: '3px',
          backgroundColor: 'white',
          color: '#000',
          fontSize: '20px', // Slightly smaller close button
          border: 'none',
          cursor: 'pointer',
        }}>
          &times;
        </button>
      </div>
    </div>
  ) : null;
};

export default SimpleDialog;
