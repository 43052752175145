import './ArrowButton.css';

function ArrowButton(props) {
  return (
    <button onClick={props.onClickEdit} className="arrow-button2">
      <span className="arrow2">&#8592;</span>
      <span className='arrowButtonText2'>{props.text}</span>
      
    </button>
  );
}

export default ArrowButton;
