import "./About.css"
import { ReactComponent as SpecializationSVG } from '../../SVGs/Specialization.svg';
import { useState } from "react";
import Popup from "../PopUps/PopUp";
import { doc, setDoc } from "firebase/firestore"; 
import {db, auth, analytics} from "../../config/firebase-config"
import { useStateValue } from "../StateProvider";
import { Navigate, useParams } from 'react-router-dom';
import myImage from './about-5-32.png';
import RingLoader from "react-spinners/RingLoader";
import { useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import AboutDialog from "../AboutDialog/AboutDialog";
import { logEvent } from "firebase/analytics";




function About() {
  
  const location=useLocation();
    const [specializations, setSpecializations] = useState();
    const [{basket,user,spec}] = useStateValue();
    const [isOpen, setIsOpen] = useState(false);
    const { name } = useParams();
    const [profileUserType, setProfileUserType] = useState('');
    const input1State = basket[0]?.spec1 ? basket[0].spec1 : '';
    const input2State = basket[0]?.spec2 ? basket[0].spec2 : '';
    const input3State = basket[0]?.spec3 ? basket[0].spec3 : '';
    const [input1, setInput1] = useState(input1State);
const [input2, setInput2] = useState(input2State);
    const [input3, setInput3] = useState(input3State);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const handleOpen = () => {
        setIsOpen(true);
        logEvent(analytics,"open_specialization_dialog")
    }

    const openDialog = () => {
        setIsDialogOpen(true);
        logEvent(analytics,'open_long_description_dialog')
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        setAboutMe(myProfile ? basket[0]?.aboutMe : spec?.aboutMe);
        logEvent(analytics,'close_long_description_dialog')
    };


const basketName = basket && basket.length > 0 ? basket[0]?.name + '.' + basket[0].surname : '';
const basketNameNav = decodeURIComponent(basketName.toLowerCase().trim());
const nameTrimmed = name.trim().trimEnd();
const basketNameNavTrimmed = decodeURIComponent(basketNameNav.toLowerCase().trim().trimEnd());
const myProfile = nameTrimmed === basketNameNavTrimmed;
const basketName2 = basket[0]?.name + '.' + basket[0]?.surname;
    const myProfile2 = name.trim() === basketName2.toLowerCase().trim().trimEnd();
    const [aboutMe, setAboutMe] = useState(myProfile ? basket[0]?.aboutMe : spec?.aboutMe)


    const handleClose = async () => {
        setIsOpen(false);
        setInput1(basket[0]?.spec1 ? basket[0].spec1 : '');
        setInput2(basket[0]?.spec2 ? basket[0].spec2 : '');
        setInput3(basket[0]?.spec3 ? basket[0].spec3 : '');
        logEvent(analytics, "close_specialization_dialog",)
    }

// Debugging the values of userType
const basketUserType = basket[0] ? basket[0]?.userType : 'undefined';
const specUserType = spec ? spec?.userType : 'undefined';


// Determining the value of specTest
const specTest = myProfile ? basketUserType : specUserType;

    return (
      <div className="About">
          { basket[0]?.userType || spec?.userType  !== "client" ? (
              <>
                  <div className="SpecializationHeader">
                      <SpecializationSVG />
                      <div className='SpecializationText'>{specTest === "client" ? "Potrebna mi je pomoć oko:" : "Specijalizacije"}</div>
                  </div>   
                  <div className='Specs2'>
                      <div className='S11'>
                          {myProfile ? input1 : spec.spec1 }
                      </div>
                      <div className='S22'>
                          {myProfile ? input2 : spec.spec2 }
                      </div>
                      <div className='S33'>
                          {myProfile ? input3 :  spec.spec3 }
                      </div>
                      {name === basketNameNav && (
                          <>
                              <div className="plusSpecialization" onClick={handleOpen}> { specTest === "client" ? "+ Dodaj" : "+ Dodaj specijalizacije"}</div>
                              <Popup 
                                  input1={input1} 
                                  input2={input2} 
                                  input3={input3} 
                                  setInput1={setInput1}
                                  setInput2={setInput2} 
                                  setInput3={setInput3} 
                                  isOpen={isOpen} 
                                    handleClose={handleClose} 
                                    setIsOpen={setIsOpen}
                              />
                          </>
                      )}
                  </div>
              </>
          ):null}
          <div className="AboutMe">
              <img className="AboutMeImage" src={myImage} alt="About Me" />
              <div className="AboutMeTitle">
                  O meni
              </div>
          </div>
          {myProfile ? <p onClick={openDialog} className="AddDescription">{basket[0].aboutMe ? "Izmeni opis +" : "Dodaj opis +"}</p> : null}
          <p className="AboutMeDescription">{aboutMe}</p>
          <AboutDialog setIsDialogOpen = {setIsDialogOpen} onClose={closeDialog} setAboutMe = {setAboutMe} aboutMe={aboutMe} isDialogOpen={isDialogOpen} />
      </div>
  );
                      }  
export default About ;