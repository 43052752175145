import './Card.css'
import { NavLink } from 'react-router-dom';
import { ReactComponent as Star } from '../../SVGs/Star.svg';
import { useEffect,useState } from 'react';
import image from '../ProfileCard/Default_pfp.jpg'

function Card(props) {
    const nameSur = `${props.name || ''} ${props.surname || ''}`; 
    const navNameSur = nameSur.replace(" ",".").toLocaleLowerCase();
    const price = props.price;
    const onClickNav = () => props.onClick(navNameSur,price);
    const [charCount, setCharCount] = useState(40);

    useEffect(() => {
        const updateCharCount = () => {
          if (window.innerWidth > 550) {
            setCharCount(70);
          } else if(window.innerWidth > 450) {
            setCharCount(55);
          } else {
            setCharCount(40);
          }
        };

        updateCharCount();
  
        window.addEventListener('resize', updateCharCount);
        return () => window.removeEventListener('resize', updateCharCount);
      }, []);

    if (props.search && !nameSur.toLowerCase().includes(props.search.toLowerCase())) {
        return null;
    }
    return (
        <div className='Card' onClick={onClickNav}>
            <NavLink className='ProfilePicContainer'>
                <img className='ProfilePic' alt={nameSur} src={props.imageURL ? props.imageURL : image} />
            </NavLink>
            <div className='NameRating'>
                <h3 className='Name'>{nameSur}</h3>
                <div className='Rating'>
                    <Star />
                    <div className='RatingNumber'>
  {props.rating ? Number(props.rating.toFixed(2)) : "-"}
</div>

                </div>
            </div>
            <p className='CardText'>{props.description ? props.description.substring(0, charCount) + '...':''}</p>
            <div className='Specs'>
                <div className='S1'><div className='SDiv'>{props.spec1?.substring(0,41)}</div></div>
                <div className='S2'><div className='SDiv'>{props.spec2?.substring(0,41)}</div></div>
                <div className='S3'><div className='SDiv'>{props.spec3?.substring(0,41)}</div></div>
                <div className='CardPrice'>Cena:{props.price}din.</div>
            </div>
        </div>
    );
}

export default Card;
