import './AppointmentSettings2.css';
import Header from '../Header/Header';
import ArrowButton from '../ArrowButton/ArrowButton';
import Calendar from 'react-calendar';
import './MyCalendar.css';
import AppointmentsSpec from '../AppointmentsSpec/AppointmentsSpec';
import {useNavigate } from 'react-router-dom';
import { useStateValue } from '../StateProvider';
import { analytics, auth } from '../../config/firebase-config';
import { useState, useEffect } from 'react';
import Footer from '../Footer/Footer';
import { logEvent } from 'firebase/analytics';


function AS2(props) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [{ basket }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const onChange = (date) => {
    setSelectedDate(date);
  }
  useEffect(() => {
    if (auth.currentUser && basket[0].length > 0 && !basket[0]?.privacyPoliceAccepted) {
      navigate('/terms-and-conditions')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite politiku privatnosti.')

    } else if (auth.currentUser && basket[0].length > 0 && !basket[0]?.termsAndConditionAccepted) {
      navigate('/privacy-policy')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite uslove korišćenja.')
    }
  }, [])
  
    return (
        <div className='AS2'>
        <Header name={basket[0].name} surname={basket[0].surname} />
          <div className="AS2BigContainer">
            <div className='AS2SmallContainer'>
                <ArrowButton onClickEdit={props.onClickEdit} text='Organizacija termina'/>
                <div className='EditContainer'>
                  <span className='EditText'> Podešavanja</span>
                  <button className='EditButton'>Edit</button>
                </div>
            <Calendar tileClassName={({ date, view }) => {
              if (selectedDate && view === 'month' && date.getTime() === selectedDate.getTime()) {
                return 'react-calendar__tile--active';
              }
            }} onChange={(value) => { onChange(value); props.setCurrentDateObject(value); props.setCurrentDate(value.toLocaleDateString('en-US', props.options)); logEvent(analytics, 'change_appo_date',{date: value})}} 
                          formatShortWeekday={props.formatShortWeekday} />
                {!props.loading2 ?<AppointmentsSpec process={props.process} appoNames = {props.appoNames} appoHours = {props.appoHours} isDialogOpen = {props.isDialogOpen} setIsDialogOpen = {props.setIsDialogOpen}
                                   isDialogOpen2 = {props.isDialogOpen2} setIsDialogOpen2 = {props.setIsDialogOpen2} currentDate={props.currentDate} setTimes = {props.setTimes} times={props.times}/>:'Loading .... '}
            </div>
        </div>
        <Footer/>
        </div>
    )
}  
export default AS2;