import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase-config";
import { auth, db } from "../../config/firebase-config";
import { doc,setDoc } from "firebase/firestore";

const Popup = (props) => {
  const {isOpen, handleClose, input1, input2, input3, setInput1, setInput2, setInput3, setIsOpen} = props;

  const handleChange = (setter) => (e) => setter(e.target.value);

  const handleSave = async() => {
    setIsOpen(false);
      await setDoc(doc(db, "Clients", auth.currentUser.uid), {
        spec1: input1,
        spec2: input2,
        spec3: input3,
      }, { merge: true });
    logEvent(analytics,"specializations_set")
  }

  return isOpen ? (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.7)'
    }}>
      <div style={{
        padding: '20px',
        backgroundColor: 'hsla(185, 33%, 40%, 1)',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative', // Needed for positioning the close button
      }}>
        <button onClick={handleClose} style={{
          position: 'absolute',
          top: 0,
          right: 0,
          background: 'none',
          border: 'none',
          color: 'white',
          cursor: 'pointer',
          paddingTop: '0.3rem',
          fontSize: '0.8rem',
        }}>X</button>

        <input className='PopUpInput' type="text" value={input1} onChange={handleChange(setInput1)} placeholder="primer: anksioznost" />
        <input className='PopUpInput' type="text" value={input2} onChange={handleChange(setInput2)} placeholder="primer: depresija" />
        <input className='PopUpInput' type="text" value={input3} onChange={handleChange(setInput3)} placeholder="primer: problemi u školi" />
        <button className='PopUpButton' onClick={handleSave} style={{ alignSelf: 'flex-end', marginTop: '10px' }}>Snimi</button>
      </div>
    </div>
  ) : null;
};

export default Popup;
