import "./Alert.css"

function Alert(props) {
    return (
    <div className='Alert' style={{backgroundColor : props.backgroundColor, width : props.width}}>
        <div className="AlertSignContainer">
            <div className= 'AlertBackground' style={{color: props.signColor}}>
                !
            </div>
        </div>
        <div className="AlertText" style={{color: props.textColor}}>
            {props.text}
        </div>
    </div>
)}
export default Alert;