    import React, { useEffect, useState } from 'react';
    import './AppointmentDialog.css';
    import { analytics, db } from '../../config/firebase-config';
    import {  getDoc } from "firebase/firestore";
    import { auth } from '../../config/firebase-config';
    import {  setDoc } from "firebase/firestore";
    import { Link } from 'react-router-dom';
    import { useLocation } from 'react-router-dom';
    import LinkDialog from './LinkDialog';
    import { useStateValue } from '../StateProvider';
    import { query, collection, where, getDocs, updateDoc, doc } from "firebase/firestore";
    import { useParams } from 'react-router-dom';
    import { logEvent } from 'firebase/analytics';


    

    function AppointmentDialog({ isOpen, onClose2, profileLink, mapLink, meetLink, location, id, imageUrl, price, account,userType, description,account2  }) {

        const [{ basket,spec }] = useStateValue();
        const { name } = useParams();

        const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);

  async function updateMeetLink(db, basket, headerN, headerS, id, inputValue) {
    try {

        // Construct the appointment document ID
        const [idName, idSurname] = id.split(' ');
        

        // First Query
        const q1 = query(collection(db, "Clients"), where("name", "==", basket[0].name), where("surname", "==", basket[0].surname));
        const querySnapshot1 = await getDocs(q1);
        if (!querySnapshot1.empty) {
            const clientDoc1 = querySnapshot1.docs[0];
            const appointmentDocRef1 = doc(db, "Clients", clientDoc1.id, "Appointments", id);
            await updateDoc(appointmentDocRef1, { meetLink: inputValue });
        }

        // Second Query
        const q2 = query(collection(db, "Clients"), where("name", "==", idName), where("surname", "==", idSurname));
        const querySnapshot2 = await getDocs(q2);
        if (!querySnapshot2.empty) {
            const clientDoc2 = querySnapshot2.docs[0];
            const appointmentDocRef2 = doc(db, "Clients", clientDoc2.id, "Appointments", basket[0].name + ' ' + basket[0].surname);
            await updateDoc(appointmentDocRef2, { meetLink: inputValue });
        }

    } catch (error) {
        console.error("Error updating meetLink: ", error);
    }
}




    const handleLinkSubmit = (link) => {
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
    link = 'https://' + link;
}
        const noDot = name?.replace("."," ");
   function capitalizeFirstLetterOfEachWord(str) {
    return str?.toLowerCase()?.split(' ')?.map(function(word) {
        return word.charAt(0).toUpperCase() + word.substring(1);
    }).join(' ');
  }
  const headerName = capitalizeFirstLetterOfEachWord(noDot);
  const parts = headerName?.split(' ');
    const headerN = parts[0]; // First name
    let headerS = '';
    if (parts.length > 1) {
      // Join the remaining parts as the surname
      headerS = parts.slice(1)?.join(' ');
  }
        updateMeetLink(db, basket, headerN, headerS,id, link);
    };
        
        const locationURL = useLocation() 
        
        const [linkName, setLinkName] = useState('');
        const handleSubmit = async () => {
            try {
                // This is where you handle your link submissions to Firestore or any other logic you may want to perform

            } catch (error) {
                console.error("Error processing the link:", error);
            }

            onClose2();
        };
       

        if (!isOpen) {
            return null;
            
        }

        return (
            <div className="dialogOverlay">
                <div className="dialogContainer">
                    <div className="dialogHeader">
                        Informacije o teminu
                        <span className="closeDialog" onClick={(e) => { e.stopPropagation(); onClose2(); }}>x</span>
                    </div>
                    <div className="dialogMain">
                        <p>Profil: <Link onClick={() => logEvent(analytics, 'look_appointment_profile', {
                            receiver_name: id,
                            price: price

                        })} to={`/profile/${id.split('_')[0].toLowerCase().replace(' ', '.')}`}>{id.split('_')[0]} </Link></p> 
                        <p>Link: {location ? <a onClick={() => logEvent(analytics, 'go_to_appointment_link', {
                            receiver_name: id,
                            price: price

                        })} href={location} target="_blank" rel="noopener noreferrer">{location} </a> : <><span className='ADSpan' onClick={() => setIsLinkDialogOpen(true)}>Dodaj termin +</span> </>}</p>
                        
                        {locationURL && locationURL.pathname.includes('profile') && account ? (
                            <>
                                <p>Račun: <span>{account}</span></p>
                                {account2.length !== 0 && 
                                <>
                                <p>IBAN: <span>{account2}</span></p>
{/*                                 <p style={{fontSize:'0.8rem'}}>(Ukoliko Vaš izabrani ponuđač usluge nije iz iste države kao i Vi, broj računa za uplatu je IBAN.)</p>
 */}                                </>
                                }
                                { description && 
                                <>
                                <p>Poruka ponuđača:</p>
                                <p style={{color:"grey", wordBreak:"break-all"}}>{description}</p>
                                
                                </>
                                }
                            </>
                        ) : null}
                        <p>Cena: <span>{price}</span></p>
                    </div>
                    <div className="dialogFooter">
                        <button className='AppoDialogButton' onClick={handleSubmit}>Izađi</button>
                    </div>
                    
                    <LinkDialog
                        isOpen={isLinkDialogOpen}
                        onClose={() => setIsLinkDialogOpen(false)}
                        onSubmit={handleLinkSubmit}
                        id={id}
                    />
                </div>
            </div>
        );
        
    }

    export default AppointmentDialog;
