import './Chat.css';
import Header from '../Header/Header'
import {MessageBox } from 'react-chat-elements';
import {ChatItem} from 'react-chat-elements';
import React from 'react';
import { Input } from 'react-chat-elements';
import { Button } from 'react-chat-elements';
import { useNavigate, useParams } from 'react-router-dom';
import { collection,query,where,getDocs, arrayUnion, startAfter, updateDoc} from 'firebase/firestore';
import { db,app, auth, analytics } from '../../config/firebase-config';
import { useState, useEffect , useRef, useMemo } from 'react';
import { useStateValue } from '../StateProvider';
import { setDoc,doc,serverTimestamp,orderBy } from 'firebase/firestore';
import { RingLoader } from 'react-spinners';
import { onSnapshot, or, limit } from 'firebase/firestore';
import useWindowWidth from '../WindowsWidthHook';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Joyride, {STATUS, ACTIONS } from 'react-joyride';
import SpeechBubble from "../SpeechBubble/SpeechBubble";
import { ReactComponent as Avatar } from '../../SVGs/Avatar.svg';
import { getMessaging, getToken} from 'firebase/messaging';
import { logEvent } from "firebase/analytics";
import VideoPopUp from '../PopUps/VideoPopUp/VideoPopUp';
import emptyImage from '../ProfileCard/Default_pfp.jpg';

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
let myNotification = !('Notification' in window) ? {} : Notification;


export default function Chat() {
    const width = useWindowWidth();
    const [messagesArray, setMessagesArray] = useState([]);
    const [currentInput, setCurrentInput] = useState('')
    const {name} = useParams();
    var inputReferance = React.createRef();
    const chatItemReferance = useRef(null);
    const [chatName,setChatName] = useState();
    const [chatSurname,setChatSurname] = useState();
  const [chatImg, setChatImg] = useState(emptyImage);
    const [{basket}, dispatch] = useStateValue();
    const [currentChatDoc, setCurrentChatDoc] = useState(null);
    const [otherUserName, setOtherUserName] = useState(name);
    const [loading, setLoading] = useState(true);
    const scrollableRef = useRef(null);
    const [userChats, setUserChats] = useState([]);
    const [chatDoc, setChatDoc] = useState([]);
    const [chatNumber,setChatNumber] = useState(0);
    const [docHasUser,setDocHasUser] = useState(false);
    const [chatClicked, setChatClicked] = useState(false);
    const [messagelistStyle,setMessageListStyle] = useState({});
    const [chatlistStyle,setChatListStyle] = useState({});
    const [inputStyle,setInputStyle] = useState({});
    const [loadStyle,setLoadStyle] = useState({});
    const [firstMessage,setFirstMessage] = useState(false);
    const [chatToken, setChatToken] = useState([]);
    const [notificationSend,setNotificationSend] = useState(false);
    const [run, setRun] = useState(false);
    const [avatarSeenThisSesion3, setAvatarSeenThisSesion3] = useState(false);

    const [avatarSeen, setAvatarSeen] = useState(false);
    const [isPopUpVideoOpen, setIsPopUpVideoOpen] = useState(false);
    const [run3, setRun3] = useState(false);
    const [currentChatDocData, setCurrentChatDocData] = useState({});
    const [chatsLoading, setChatsLoading] = useState(false);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/evaapp-46d63.appspot.com/o/Instrukcije%20za%20ajfon.mp4?alt=media&token=e06bb3f1-019d-4895-8a7d-ba24dd45813e';
    const openVideoPopup = () => setIsPopUpVideoOpen(true);
  const closeVideoPopup = () => setIsPopUpVideoOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser && !basket[0]?.privacyPoliceAccepted) {
      navigate('/terms-and-conditions')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite politiku privatnosti.')

    } else if (auth.currentUser && !basket[0]?.termsAndConditionAccepted) {
      navigate('/privacy-policy')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite uslove korišćenja.')
    }
  }, [])


    const handleAvatarNotificationButtonClick = () => {
      if (isIOS && !isInStandaloneMode )
      {
        setRun3(true);
            setRun(false);
            logEvent(analytics, 'open_video_avatar', {
              tutorial_location: 'chat',
              mode: isInStandaloneMode ? 'standalone' : 'browser',
            });
        
      }else{

        logEvent(analytics, 'allow_notification', {
          tutorial_location: 'chat',
          mode: isInStandaloneMode ? 'standalone' : 'browser',
        });
        
        Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          // With permission, now get messaging token
          const messaging = getMessaging();
          getToken(messaging, { vapidKey: 'BIUi3uPzzl-s6RnTjQR-KlYUiNHP0o3JfoFZvqZlKxGq_gK2dcjo6SOtHH8AsqZluiFEN6oopH6so08olnvIIEQ' })
            .then((currentToken) => {
              if (currentToken) {
                // Save or update the token in your database
                const clientDocRef = doc(db, 'Clients', auth.currentUser?.uid);
                setDoc(clientDocRef, { token: arrayUnion(currentToken) }, { merge: true });
              } else {
                console.log('No registration token available. Make sure notifications permissions are granted.');
              }
            }).catch((error) => {
              console.error('Error getting registration token:', error);
            });
        } else {
          console.log('Notification permission not granted.');
        }
      });}
    }
    
    const [steps] = useState([
        {
          target: '.Chat',
          content: (
            <div className="AvatarContainer">
              <Avatar />
              <SpeechBubble>
                 <p>
                    Ukoliko želiš da dobiješ obaveštenje kada ti neko pošalje poruku, klikni na dugme ispod.
                    <ArrowDownwardIcon className='ArrowDownwardIcon' style={{fontSize:"1.2rem", position:"relative", top:'0.2rem'}} />
                    <br/>
                    <button
                      style={{
                        display: "flex",
                        color: "white",
                        backgroundColor: "hsla(174, 40%, 44%, 1)",
                        padding: "3% 4%",
                        borderRadius: "2vh",
                        transition: "all .3s ease",
                        cursor:"pointer",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1rem",
                        borderWidth: "0.05vh",
                        fontWeight: "500",
                        width:"100%",
                        marginTop: "2vh",        
                      }}
                      onClick={handleAvatarNotificationButtonClick}
                      aria-label="Dozvoli obaveštenja"
                    >
                      Dozvoli obaveštenja
                    </button>
                  </p>
              </SpeechBubble>
            </div>
          ),
          disableBeacon:true,
          placement: "center",
          styles:{
                options: {
                  arrowColor: '#fff',
                  backgroundColor: "hsl(165, 12%, 69%)",
                  beaconSize: 36,
                  overlayColor: 'rgba(0, 0, 0, 0.5)',
                  primaryColor: 'rgba(123, 199, 191, 0.9)',
                  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                  textColor: '#333',
                  width: "min(30rem, 90vw,90vh)",
                  zIndex: 100,
                  
                },
                
                
              }
        },
    ]);

    const [steps3, setSteps3] = useState([
    
      {
        target: '.Chat',
        content:
        <> 
        <div className="AvatarContainer">
          <Avatar/>
          <SpeechBubble >
      <p>
        S obzirom da koristiš iPhone, potrebno je da aplikaciju skineš na telefon kako bi mogao/la da primaš notifikacije.
        <br/>
        U kratom video tutorijalu ispod možeš videti kako da to uradiš u 3 klika.
      </p>
    
  </SpeechBubble>
  
  
        </div>
        <img onClick={openVideoPopup} className="addToHomeInstructionVideo"
        src='/play-button-6694069_1280.png'
        >
          
        </img>
       </>,
        disableBeacon:true,
        placement: "center",
        styles:{
              options: {
                arrowColor: '#fff',
                backgroundColor: "hsl(165, 12%, 69%)",
                beaconSize: 36,
                overlayColor: 'rgba(0, 0, 0, 0.5)',
                primaryColor: 'rgba(123, 199, 191, 0.9)',
                spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                textColor: '#333',
                width: "min(30rem, 90vw,90vh)",
                zIndex: 100,
                position: "relative",
                
              },      
            }
      },
      ]);
    
    const handleJoyrideCallback = (data) => {
        const { action, index, type, status } = data;
    
        if (index === 0 && type === 'step:before') {
          // Log 'tutorial_begin' event
          logEvent(analytics, 'tutorial_begin', {
            tutorial_location: 'chat'
          });
        }
      
        // Tutorial Complete
        if (status === 'finished') {
          // Log 'tutorial_complete' event
          logEvent(analytics, 'tutorial_complete', {
            tutorial_location: 'chat'
          });
        }
      
        // Tutorial Closed
        if (status === 'skipped' || (status === 'closed' && index < steps.length - 1)) {
          // Log 'tutorial_closed' event, indicating the tutorial was closed before completion
          logEvent(analytics, 'feed_tutorial_closed', {
            step_index: index + 1, // Indicates the last step viewed before closing
            reason: status, // 'skipped' or 'closed'
            tutorial_location: 'chat'
          });
        } 
    
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
          setRun(false); // Stops the tour
        }
        // You can add more conditions here based on your requirements.
      };
      const handleJoyrideCallback2 = (data) => {
        const { action, index, type, status } = data;
    
        if (index === 0 && type === 'step:before') {
          // Log 'tutorial_begin' event
          logEvent(analytics, 'tutorial_begin', {
            tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
          });
        }
      
        // Tutorial Complete
        if (status === 'finished') {
          // Log 'tutorial_complete' event
          logEvent(analytics, 'tutorial_complete', {
            tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
          });
          
        }
      
        // Tutorial Closed
        if (status === 'skipped' || (status === 'closed' && index < steps.length - 1)) {
          // Log 'tutorial_closed' event, indicating the tutorial was closed before completion
          logEvent(analytics, 'feed_tutorial_closed', {
            step_index: index + 1, // Indicates the last step viewed before closing
            reason: status, // 'skipped' or 'closed'
            tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
          });
         
        } 
    
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
          setRun3(false); // Stops the tour
          setRun(true);
        }
        // You can add more conditions here based on your requirements.
      };


  const sendNotification = async (receiverToken, title, body) => {
    const functions = getFunctions(app);
    const sendNotificationFunction = httpsCallable(functions, 'sendNotification'); // Update the function name

    try {
      const result = await sendNotificationFunction({ receiverToken, title, body });
      console.log('Notification sent:', result.data);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };
    

    const senderName = useMemo(() => 
    (basket[0]?.name.toLowerCase() + '.' + basket[0]?.surname.toLowerCase()) || '',
    [basket]
);

    useEffect(()=>{
        if (width < 760 && !chatClicked) {
            setMessageListStyle({display:'none'});
            setChatListStyle({display:"block"}); 
            setInputStyle({width:'100%'})
}else if(width < 760 && chatClicked){
            setMessageListStyle({display:'block'});
            setChatListStyle({display:"none"}); 
}else{setMessageListStyle({});
setChatListStyle({});
setInputStyle({});
    
}
},[width,chatClicked]) 

useEffect(()=>{
    if(messagesArray.length<10) {
        setLoadStyle({display:"none"})
    } else {
        setLoadStyle({})
    }
},[messagesArray])

    function convertString(inputStr) {
        return inputStr.split('.').map(word => {
            if(word.indexOf(' ') === -1){
                return word.charAt(0).toUpperCase() + word.slice(1);
            } else {
                return word.split(' ').map(surnameWord => {
                    return surnameWord.charAt(0).toUpperCase() + surnameWord.slice(1);
                }).join(' ')
            }
            
        });
    }

  const getChats = async () => { 
    setChatsLoading(true);
        const uniqueChats = [];
        const q = query(collection(db, "Messages"), where("users", "array-contains", senderName));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            let data = doc.data().users;
            const result = data.filter(item => item !== senderName);
            uniqueChats.push(convertString(result[0]));
        });
    setUserChats([...uniqueChats]);
    setChatsLoading(false);
    };  
  const getChats2 = async () => {
    setChatsLoading(true);
    let chatDocArray = []
    const chatsPromises = [...userChats].map(async (chat) => {
        const q = query(
            collection(db, "Clients"), 
            where("name", "==", chat[0]), 
            where("surname", "==", chat[1])
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return;
        }

        querySnapshot.forEach((doc) => {
            
            chatDocArray.push(doc.data());
            
        });
    });

    await Promise.all(chatsPromises);
    setChatDoc([...chatDocArray]);
    setChatsLoading(false);
  
}
    useEffect(()=>{
       getChats()
    
    },
    []);

    useEffect(() => {
        if (userChats.length > 0 && chatNumber < 1) {
            getChats2();
            setChatNumber(1);
        }
    }, [userChats]);
    
    function capitalizeFirstLetter(str) {
        if (!str || typeof str !== 'string') return ''; 
        if(str.indexOf(' ') === -1) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        } else {
            return str.split(' ').map(str1 => {
            return str1.charAt(0).toUpperCase() + str1.slice(1);
            }).join(' ') 
        }
        
    }
    let queryArray = [otherUserName,senderName]
    let queryArray2 = [senderName,otherUserName]
    
        let [nameN ,nameS] =''
    if(name) {[nameN ,nameS] = name.split('.');}

    const getFirstData = async () => {
        
        
          const q = query(collection(db, "Clients"), where("name", "==", capitalizeFirstLetter(nameN)), where("surname", "==", capitalizeFirstLetter(nameS)));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
        const fetchedName = doc.data().name;
        const fetchedSurname = doc.data().surname;
        const fetchedImg = doc.data().imageURL;
        const token = doc.data().token;

        setChatName(fetchedName);
        setChatSurname(fetchedSurname);
          if (fetchedImg) {
            setChatImg(fetchedImg)
          };
        setChatToken(token);
        chatItemReferance.style = {backgroundColor:'black'};      
    });
};

        useEffect(() => {    
          if (name !== senderName) {
            getFirstData();
          }
        }, []);

  async function getChatDoc() {
    setMessagesLoading(true);
        const q = query(collection(db, "Messages"), or(where("users", "==", queryArray), where("users", "==", queryArray2)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setCurrentChatDoc(querySnapshot.docs[0].id);
          
            
        }else{
          setCurrentChatDoc('none');
          
    }
    setMessagesLoading(false);
  } 

        useEffect(()=>{    
        getChatDoc();},
        [otherUserName]);

        useEffect(() => { 
            if(currentChatDoc ) {
                const subCollectionRef = collection(doc(db, "Messages", currentChatDoc), "messages");
                const q = query(subCollectionRef, orderBy('date', 'desc'), limit(10));
                const unsubscribe = onSnapshot(q, (snapshot) => {
                    const updatedMessages = snapshot.docs.map(doc => doc.data());
                    setMessagesArray(updatedMessages.reverse()); // Reversing because we're getting the last 10 in descending order
                });
                return () => unsubscribe();
            } 
            
            setLoading(false);
        }, [otherUserName, currentChatDoc]);
        
  function handleChatItemClick(e) {
      
        document.querySelectorAll('.beActive').forEach((el) => {
            el.classList.remove('beActive');
        })
        let element;
        if (e.target.classList.contains("rce-citem"))
        {element= e.target;}else if(e.target.parentElement.classList.contains("rce-citem")) {element= e.target.parentElement;} else {
            element= e.target.parentElement.parentElement.parentElement;
        }
        element.classList.add('beActive');
        const element2 =  element.querySelector('.rce-citem-body--top-title');
        const element2Addapted = element2.textContent.toLowerCase().replace(' ','.');
        setOtherUserName(element2Addapted);
      setChatClicked(true);
      const currentChatInfo = chatDoc.find(chat => chat.name === element2.textContent.split(' ')[0] && chat.surname === element2.textContent.split(' ')[1]);
      setCurrentChatDocData(currentChatInfo);
      if (currentChatInfo?.token) {
        setChatToken(currentChatInfo.token);
      }     
      logEvent(analytics,'chat_user_selected')
    
  }    
  


  const functions = getFunctions();
  const sendEmail = httpsCallable(functions, 'sendEmail');

  async function onButtonClick() {
    if (currentChatDoc !== "none") {
      // Send notification if not already sent and there are tokens available
      if (!notificationSend ) {
        const title = 'EVA poruka';
        const body = 'Imate novu poruku';
        chatToken.forEach(tok => {
          sendNotification(tok, title, body);
          
        });
        try {
          const emailData = {
            to: currentChatDocData.email, // Replace with the recipient's email address
            subject: 'Nova poruka na EVA aplikaciji',
            messageBody: `
                      <b>Dobili ste novu poruku od korisnika: <a href="https://evawellbeing.app/profile/${basket[0].name.toLowerCase() + '.' + basket[0].surname.toLowerCase()}">${basket[0].name + ' ' + basket[0].surname}</b> 
                      <br><br>
                      <b>Poruka:</b> ${currentInput}
                      <br><br>
                      <b>Link ka aplikaciji:</b> <a href="https://evawellbeing.app/">Eva Wellbeing</a>
`     
          };
          (async () => {
            try {
              await sendEmail(emailData);
              console.log("Email scheduled to send with delay");
            } catch (error) {
              console.error("Error scheduling email:", error);
            }
          })();
        } catch (error) {
          console.error("Error sending email:", error);
        }
        setNotificationSend(true);
      }

      // Save the message to the database
      await setDoc(doc(db, "Messages", currentChatDoc, 'messages', `${Date.now()}`), {
        sender: senderName,
        message: currentInput,
        date: serverTimestamp()
      });

      // Check and update avatar seen status
      if (!basket[0].chatAvatarSeen) {
        setRun(true);
        dispatch({
          type: 'UPDATE_BASKET_PROPERTY',
          property: 'chatAvatarSeen', // The name of the property you want to update
          value: true // The new value for the property
        });
        try {
          const userRef = doc(db, "Clients", auth.currentUser.uid);
          await updateDoc(userRef, {
            chatAvatarSeen: true
          });
        } catch (e) {
          console.error("Error updating avatar seen status:", e);
        }
      }
    } else {
      // Create a new chat document if it doesn't exist
      await setDoc(doc(db, "Messages", `${otherUserName} ${senderName}`), {
        users: [senderName, otherUserName],
        time: serverTimestamp()
      });
      setMessagesArray(prev => [...prev, {
        message: currentInput,
        users: [senderName, otherUserName],
        time: serverTimestamp(),
        sender: senderName
      }]);

      // Save the message to the database
      await setDoc(doc(db, "Messages", `${otherUserName} ${senderName}`, 'messages', `${Date.now()}`), {
        sender: senderName,
        message: currentInput,
        users: [senderName, otherUserName],
        date: serverTimestamp()
      });

      // Send notification if not already sent and there are tokens available
      if (!notificationSend && chatToken.length > 0) {
        const title = 'EVA poruka';
        const body = 'Imate novu poruku';
        chatToken.forEach(tok => {
          sendNotification(tok, title, body);
        });
        try {
          const emailData = {
            to: currentChatDocData.email, // Replace with the recipient's email address
            subject: 'Nova poruka na EVA aplikaciji',
            messageBody: `
                      <b>Dobili ste novu poruku od korisnika:</b> ${basket[0].name + ' ' + basket[0].surname}
  <br><br>
  <b>Poruka:</b> ${currentInput}
  <br><br>
  <b>Link ka aplikaciji:</b> <a href="https://evawellbeing.app/">Eva Wellbeing</a>
`          };
          const result = await sendEmail(emailData);
          console.log('Email sent successfully:', result.data);
        } catch (error) {
          console.error("Error sending email:", error);
        }
        setNotificationSend(true);
      }

      // Check and update avatar seen status
      if (!basket[0].chatAvatarSeen) {
        setRun(true);
        dispatch({
          type: 'UPDATE_BASKET_PROPERTY',
          property: 'chatAvatarSeen', // The name of the property you want to update
          value: true // The new value for the property
        });
        try {
          const userRef = doc(db, "Clients", auth.currentUser.uid);
          await updateDoc(userRef, {
            chatAvatarSeen: true
          });
        } catch (e) {
          console.error("Error updating avatar seen status:", e);
        }
      }
      setFirstMessage(true);
    }

    // Clear the input field
    try {
      document.querySelector('.rce-input-textarea').value = '';
    } catch (error) {
      console.error("Error clearing input:", error);
    }
    setCurrentInput('');

    // Send email using Firebase Callable Function
    
    logEvent(analytics, 'message_sent');

  }

  // Function to send email using the Cloud Function
  

  // Function to send FCM notification using the Cloud Function
  

    

  function showMessages() {
    if (messagesLoading) {
      return (
        <RingLoader
          color={'#4dffdb'}
          loading={messagesLoading}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      );
    } else {
      return messagesArray.map((message) => {
        const right = message.sender === senderName;
        const firestoreTimestamp = message.date;
        let javascriptDate = null;

        if (
          firestoreTimestamp &&
          firestoreTimestamp.seconds !== undefined &&
          firestoreTimestamp.nanoseconds !== undefined
        ) {
          javascriptDate = new Date(
            firestoreTimestamp.seconds * 1000 +
            firestoreTimestamp.nanoseconds / 1000000
          );
        } else {
          console.warn('Invalid timestamp in message: ', message);
        }

        return (
          <MessageBox
            key={message.id} // Ensure you have a unique key for each element
            position={right ? 'right' : 'left'}
            type={'text'}
            text={message.message}
            onTitleClick={'black'}
            date={javascriptDate}
          />
        );
      });
    }
  }


     useEffect(()=>{
        [...chatDoc].map((chat) =>{
            if(chat.name.toLowerCase()+'.'+chat.surname.toLowerCase() === otherUserName) {
                setDocHasUser(true);
            }
     }) },[chatDoc]) 
     
     function handleKeyPress(event) {
        if (event.key === 'Enter' && !event.shiftKey) {  // Checks if the pressed key is "Enter" and Shift is not being held down
            event.preventDefault();  // Prevents default behavior (like submitting a form)
          onButtonClick();  // Calls the function you use for the "Send" button
          logEvent(analytics,'send_message_with_enter')
        }
    }

    function scrollToBottom() {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
        }
    }

    useEffect(() => {
        scrollToBottom();
    }, [messagesArray]);

    function ShowChats() {
      
      return (
        chatsLoading
          ?
          <RingLoader color={'#4dffdb'} loading={chatsLoading} size={50} aria-label="Loading Spinner" data-testid="loader" />

          :
          [...chatDoc].map((chat) => {
              
                return (
                    <ChatItem
           onClick={handleChatItemClick}
          avatar={chat.imageURL ? chat.imageURL : emptyImage}
           alt={'Image'}
           title={chat.name + ' ' + chat.surname}
           subtitle={'Pošalji poruku?'}
           unread={0}
           ref = {chatItemReferance}
           date={''}/>
                )
            })
        )
      }
    
const loadMoreMessages = async () => {
    const subCollectionRef = collection(doc(db, "Messages", currentChatDoc), "messages");
    const firstDocumentFromLastFetch = messagesArray[0];
    const q = query(
        subCollectionRef,
        orderBy('date', 'desc'),
        startAfter(firstDocumentFromLastFetch.date),
        limit(10)
    );

    const snapshot = await getDocs(q);
    const newMessages = snapshot.docs.map(doc => doc.data()).reverse();
    
    setMessagesArray(prevMessages => [...newMessages, ...prevMessages]);
};

if (loading) {
    return <div style={{display:"flex",
                        justifyContent:'center',
                      alignItems:"center",
                      height:'100vh'}}><RingLoader
    color={'#4dffdb'}
    loading={loading}
    size={150}
    aria-label="Loading Spinner"
    data-testid="loader"
  /></div>;
  }

    return (    
        <div className='Chat'>
        <Header name={basket[0]?.name} surname={basket[0]?.surname}  className='ChatHeader'/>
            <div className='ChatContainer' >
                <div className='chat-list' style={chatlistStyle}>
           {name!==senderName && !docHasUser   ? <ChatItem
           onClick={handleChatItemClick}
           
           avatar={chatImg}
           alt={'Image'}
           title={chatName + ' ' + chatSurname}
           subtitle={'Pošalji poruku?'}
           unread={0}
           ref = {chatItemReferance}
           date= {''}
           />
           
                   : null}
                   
                   {ShowChats()}
                   </div>
                   <div className='MIContainer' style={messagelistStyle} >
           <div className='MessageList' style={{}} ref={scrollableRef}>
            <div className="LoadBackContainer">
            <div className='messageArrow' onClick={()=>{setChatClicked(false)}}>&larr;</div>
           <div className='LoadDiv' onClick={loadMoreMessages} style={loadStyle}> učitaj još...</div>
           </div>
           {showMessages()}

           </div>

           {chatClicked?<Input
    onFocus={()=>{logEvent(analytics,'chat_input_focused')}}
    className='MessageInput'
  referance={inputReferance}
  placeholder='Piši ovde...'
  multiline={true}
  value={currentInput}
  rightButtons={<Button onClick={onButtonClick} color='white' backgroundColor='hsla(174, 40%, 63%, 1)' text='Send' />}
  onChange={(e) => setCurrentInput(e.target.value)}
  onKeyPress={handleKeyPress}
 
 
/>:null}
    </div>
    </div>
    <Joyride
  continuous
  run={run} // Ensure this is set to true to start automatically
  steps={steps}
  callback={handleJoyrideCallback}
  disableOverlayClose={true}
  locale={{
    back: 'Nazad', // Unicode for right arrow
    next: 'Sledeće', // Unicode for left arrow
    close: 'Close',
    last: 'Izađi',
  }}
/>
<Joyride
  continuous
  run={run3} // Ensure this is set to true to start automatically
  steps={steps3}
  callback={handleJoyrideCallback2}
  disableOverlayClose={true}
  locale={{
    back: 'Nazad', // Unicode for right arrow
    next: 'Sledeće', // Unicode for left arrow
    close: 'Close',
    last: 'Izađi',
  }}
/>

<VideoPopUp
isPopUpVideoOpen={isPopUpVideoOpen}
closeVideoPopup={closeVideoPopup}
videoUrl={videoUrl}
/>
        </div>
    )
}