import React, { useEffect, useState } from 'react';
import './RaitingsDialog.css';
import { db, auth, analytics } from '../../config/firebase-config';
import { setDoc, doc, where,query,collection,updateDoc,serverTimestamp,getDoc,getDocs} from "firebase/firestore";
import Rating from '@mui/material/Rating';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useStateValue } from '../StateProvider';
import image from '../ProfileCard/Default_pfp.jpg'
import { logEvent, setUserProperties } from 'firebase/analytics';


function RatingDialog({ isOpen, onClose2, setClicked, setRatingSet }) {
    const [rating, setRating] = useState(5);
    const [review, setReview] = useState('');
    const [{headerName, basket, spec}, ] = useStateValue();
    const [nameN, setNameN] = useState(' ');
    const [nameS, setNameS] = useState(' ');
    const [ratingSetLoading, setRatingSetLoading] = useState(false);
    

    useEffect(() => {
        if (headerName) {
            const parts = headerName.split(' ');
            const firstName = parts[0];
            let surname = '';
            if (parts.length > 1) {
                surname = parts.slice(1).join(' ');
            }
            setNameN(firstName);
            setNameS(surname);
        }
    }, [headerName]);
    
    const handleSubmit = async () => {
        setRatingSetLoading(true);
        try {
            const ratingsRefTherapist = doc(db, 'Clients', spec.id, 'Ratings', basket[0].name+ ' '+ basket[0].surname);
            const ratingsGivenRefClient = doc(db, 'Clients', auth.currentUser.uid, 'RatingsGiven', spec.name+ ' '+ spec.surname);
            let alreadyRatedTherapist = false;
            let alreadyRatingGivenClient = false;
            const docSnapTherapist = await getDoc(ratingsRefTherapist);
            const docSnapClient = await getDoc(ratingsGivenRefClient);
            let ratingDataTherapist = null;
            let ratingGivenDataClient = null;

            if(docSnapTherapist.exists()){
                alreadyRatedTherapist = true;
                ratingDataTherapist = docSnapTherapist.data();
            }
            if(docSnapClient.exists()){
                alreadyRatingGivenClient = true;
                ratingGivenDataClient = docSnapClient.data();
            }

            const ratingsImage = basket[0].imageURL? basket[0].imageURL : image
            let newAverageRatingTherapist = null;
            let newAverageRatingGivenClient = null;
            let newNumberOfRatingsTherapist = null;
            let newNumberOfRatingsGivenClient = null;
            
            if (!alreadyRatedTherapist) {
                newNumberOfRatingsTherapist = spec.numberOfRatings ? spec.numberOfRatings + 1 : 1;
                newAverageRatingTherapist = spec.averageRating ? ((spec.averageRating * (newNumberOfRatingsTherapist - 1)) + rating) / newNumberOfRatingsTherapist : rating;
            } else {
                newNumberOfRatingsTherapist = spec.numberOfRatings;
                newAverageRatingTherapist = ((spec.averageRating * spec.numberOfRatings) - ratingDataTherapist.rating + rating) / spec.numberOfRatings;
            }
            
            // Repeat similar logic for Client
            
            if(!alreadyRatingGivenClient) {
                newNumberOfRatingsGivenClient = basket[0].numberOfRatingsGiven ? basket[0].numberOfRatingsGiven + 1 : 1;
                basket[0].averageRatingGiven ? newAverageRatingGivenClient = (basket[0].averageRatingGiven * basket[0].numberOfRatingsGiven + rating)/newNumberOfRatingsGivenClient : newAverageRatingGivenClient = rating;
            } else {
                newNumberOfRatingsGivenClient = basket[0].numberOfRatingsGiven ? basket[0].numberOfRatingsGiven : 1;
                basket[0].averageRatingGiven  ? newAverageRatingGivenClient = (basket[0].averageRatingGiven * basket[0].numberOfRatingsGiven - ratingGivenDataClient.rating + rating)/newNumberOfRatingsGivenClient : newAverageRatingGivenClient = rating;
            }
            
            const qTherapist = query(collection(db, "Clients"), where("name","==",nameN), where("surname", "==",nameS));
            const querySnapshotTherapist = await getDocs(qTherapist);

            const updatePromises = querySnapshotTherapist.docs.map(docSnapshot => {
                const docRef = docSnapshot.ref;
                return updateDoc(docRef, {
                    numberOfRatings: newNumberOfRatingsTherapist,
                    averageRating: newAverageRatingTherapist
                });
            });
            
            await Promise.all(updatePromises);
            const qClient = query(collection(db, "Clients"), where("name","==",basket[0].name), where("surname", "==",basket[0].surname));
            const querySnapshotClient = await getDocs(qClient);

            const updatePromisesClient = querySnapshotClient.docs.map(docSnapshot => {
                const docRef = docSnapshot.ref;
                return updateDoc(docRef, {
                    numberOfRatingsGiven: newNumberOfRatingsGivenClient,
                    averageRatingGiven: newAverageRatingGivenClient
                });
            });
            
            await Promise.all(updatePromisesClient);

            await setDoc(ratingsRefTherapist, {
                 rating:rating,
                 review:review,
                 date:serverTimestamp(),
                 img:ratingsImage
                 });
            await setDoc(ratingsGivenRefClient, {
                 rating:rating,
                 review:review,
                 date: serverTimestamp(),
                 
            })
            setUserProperties(analytics, {
                number_of_ratings_given: newNumberOfRatingsGivenClient,
                average_rating_given: newAverageRatingGivenClient
            })

            logEvent(analytics, 'review_submited', {
                rating: rating,
                description_submited: recension,
                receiver_name: spec.name + ' ' + spec.surname,
                receiver_type: spec.userType,
                receiver_average_rating: spec?.averageRating,
                receiver_average_rating_given: spec?.averageRatingGiven,
                new_receiver_average_rating: newAverageRatingTherapist,
                receiver_number_of_ratings: spec?.numberOfRatings,
                new_receiver_number_of_ratings: newNumberOfRatingsTherapist,
                receiver_number_of_ratings_given: spec?.numberOfRatingsGiven,
                receiver_spec1_exists: spec?.spec1 ? 'true' : "false",
                receiver_spec2_exists: spec?.spec2 ? 'true' : "false",
                receiver_spec3_exists: spec?.spec3 ? 'true' : "false",
                receiver_long_description_set: spec?.aboutMe ? 'true' : 'false',
                receiver_short_description_set: spec?.description ? 'true' : 'false',
                receiver_profile_picture_set: spec?.imageURL ? 'true' : 'false',
                receiver_set_appo: spec?.setAppo ? 'true' : 'false',
                receiver_on_feed: spec?.onHome ? 'true' : 'false',
                receiver_price: spec?.price
            });
                ;
        } catch (error) {
            console.log(error);
            const recension = review !== '' ? "true" : "false"
            logEvent(analytics, 'review_submited_error', {
                rating: rating,
                description_submited: recension,
                receiver_name: spec.name + ' ' + spec.surname,
                receiver_type: spec.userType,
                receiver_average_rating: spec?.averageRating,
                receiver_average_rating_given: spec?.averageRatingGiven,
                receiver_number_of_ratings: spec?.numberOfRatings,
                receiver_number_of_ratings_given: spec?.numberOfRatingsGiven,
                receiver_spec1_exists: spec?.spec1 ? 'true' : "false",
                receiver_spec2_exists: spec?.spec2 ? 'true' : "false",
                receiver_spec3_exists: spec?.spec3 ? 'true' : "false",
                receiver_long_description_set: spec?.aboutMe ? 'true' : 'false',
                receiver_short_description_set: spec?.description ? 'true' : 'false',
                receiver_profile_picture_set: spec?.imageURL ? 'true' : 'false',
                receiver_set_appo: spec?.setAppo ? 'true' : 'false',
                receiver_on_feed: spec?.onHome ? 'true' : 'false',
                receiver_price: spec?.price
            });
            console.error("Error processing the link:", error);
        }
        setClicked((prev)=> prev+1);
        onClose2();
        const recension = review !== '' ? 'true' : 'false'

        setRatingSet(prev => setRatingSet(prev + 1));
        setRatingSetLoading(false);

    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="dialogOverlay">
            <div className="dialogContainer">
                <div className="dialogHeader">
                    Oceni ponuđača
                    <span className="closeDialog" onClick={(e) => { e.stopPropagation(); onClose2(); }}>x</span>
                </div>
                <div className="dialogMain">
                    <p>Ocena: 
                    <Rating
                        precision={0.5}
                        name="simple-controlled"
                        value={rating}
                        onChange={(event, newValue) => {
                            setRating(newValue);
                        }}
                    />
                    </p>
                    <p><p>Komentar (opciono):</p> 
                    <TextareaAutosize 
                        aria-label="minimum height" 
                        minRows={3} 
                        placeholder="Ostavite svoju recenziju..." 
                        value={review} 
                        onChange={e => setReview(e.target.value)} 
                            onFocus={() => {
                                logEvent(analytics, 'review_input_focused', {
                                    rating: rating,
                                    receiver_name: spec.name + ' ' + spec.surname,
                                    receiver_type: spec.userType,
                                    receiver_average_rating: spec?.averageRating,
                                    receiver_average_rating_given: spec?.averageRatingGiven,
                                    receiver_number_of_ratings: spec?.numberOfRatings,
                                    receiver_number_of_ratings_given: spec?.numberOfRatingsGiven,
                                    receiver_spec1_exists: spec?.spec1 ? 'true' : "false",
                                    receiver_spec2_exists: spec?.spec2 ? 'true' : "false",
                                    receiver_spec3_exists: spec?.spec3 ? 'true' : "false",
                                    receiver_long_description_set: spec?.aboutMe ? 'true' : 'false',
                                    receiver_short_description_set: spec?.description ? 'true' : 'false',
                                    receiver_profile_picture_set: spec?.imageURL ? 'true' : 'false',
                                    receiver_set_appo: spec?.setAppo ? 'true' : 'false',
                                    receiver_on_feed: spec?.onHome ? 'true' : 'false',
                                    receiver_price: spec?.price
})}}
                    />
                    </p>
                </div>
                <div className="dialogFooter">
                    <button className={ratingSetLoading?'RatingsButtonLoading':'RatingsButton'} onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default RatingDialog;
