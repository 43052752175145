import './Dialog.css'
import React, { useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import UploadAvatar from '../UploadAvatar/UploadAvatar';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {analytics, auth, db} from "../../config/firebase-config";
import { doc, setDoc ,getDoc } from "firebase/firestore";
import { logEvent } from 'firebase/analytics';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog(props) {
  // State
  
  const [preview, setPreview] = useState(null);
 
  

  const handleSubmit = () => {
    if(preview){
      
    const storage = getStorage();
    const storageRef = ref(storage, `${auth.currentUser.uid}/avatar.jpg`);
  
    const byteCharacters = atob(preview.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);  
    const blob = new Blob([byteArray], {type: 'image/jpeg'});
  
    // Now upload the blob
    const uploadTask = uploadBytesResumable(storageRef, blob);
  
    // Handle progress, success, and errors
    uploadTask.on('state_changed',
      (snapshot) => {
        // You can handle progress here if you want
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        });
      }
    );
  }


  function updateUserDocumentWithImageURL(userUID, imageURL) {
    const userRef = doc(db, 'Clients', userUID);  
  
  
    setDoc(userRef, { imageURL: imageURL }, { merge: true })
    .then(() => {
      console.log("Document successfully updated!");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
  }
  const storage = getStorage();
  const storageRef = ref(storage, `${auth.currentUser.uid}/avatar.jpg`);
  getDownloadURL(storageRef)
    .then((url) => {
      // You can use this URL to display the image in an <img> tag
      
      updateUserDocumentWithImageURL(auth.currentUser.uid, url);
      
    })
    .catch((error) => {
      // Handle any errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
  
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
  
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    }); 
    props.setOpen(false);
    logEvent(analytics,'profile_picture_set')
  };

  function handleClose() {
    props.setOpen(false);
    logEvent(analytics, 'close_profile_picture_dialog');
  }
  

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle 
        id="alert-dialog-slide-title" 
        style={{
          fontSize: '0.9rem', 
          color: 'hsla(229, 17%, 24%, 1)', 
          fontWeight: '500',
          fontFamily: "'Roboto', Arial, Helvetica, sans-serif",
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {"Profilna slika"}
        <div onClick={handleClose} style={{cursor:'pointer'}}>x</div>
      </DialogTitle>
      <DialogContent >
       <UploadAvatar preview={preview} setPreview={setPreview} />
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Izađi
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Sačuvaj
        </Button>
      </DialogActions>
    </Dialog>
  );
}
