import "./Book.css"
import { useStateValue } from "../StateProvider";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Book(props) {
    const { name } = useParams();
    const navigate = useNavigate();
    const [{basket,spec}] = useStateValue();
    const basketName2 = basket && basket[0] ? basket[0].name + '.' + basket[0].surname : '';
    const nameTrimmed = name.trim();
    const basketName2Trimmed = basketName2.toLowerCase().trim();
    const myProfile = nameTrimmed === basketName2Trimmed;
    
    return (<div className='Book'>
        <h4 className="BookHead">{myProfile? "Moji termini" : 'Zakažite termin ' }</h4>
        
       <p className="BookText"> {myProfile ? 'Klikom na dugme ulazite na stranicu gde možete podesiti svoje uobičajne i za dan specifične termine' : 'Zakažite online seansu' }</p>
         
         <button onClick={()=>{props.toBooking(spec,navigate)}} className={`arrow-button${props.auth.currentUser ? ' ': ' nonClickable'}`}>{myProfile ? "Podesite svoje termine":'Zakažite termin'}<span class="arrow" ></span>
</button>
    </div>
)}
 
export default Book; 