import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';


export default function UploadAvatar(props) {
    

    const onCrop = (previewData) => {
        props.setPreview(previewData);
    }
    

    const onClose = () => {
        props.setPreview(null);
    }

    return (
        <div className='AvatarContainer'>
            <Avatar
                width={340}
                height={240}
                onCrop={onCrop}
                onClose={onClose}
                borderRadius={100}
            />
            {props.preview && <img src={props.preview} alt="Preview" />}
        </div>
    );
}
