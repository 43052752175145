    import "./RatingsList.css"
    import { ReactComponent as RatingsSvg } from '../../SVGs/Raitings.svg';
    import { ReactComponent as Star2Svg } from '../../SVGs/Star2.svg';
    import { useStateValue } from "../StateProvider";
    import image from '../Card/Iva 2.jpg' 
    import { useEffect, useState } from "react";
    import RaitingDialog from '../RaitingsDialog/RaitingsDialog'
    import {where, getDocs, collection, query, onSnapshot, doc, setDoc,limit,startAfter} from "firebase/firestore";
    import { analytics, auth,db } from "../../config/firebase-config";
    import { useParams } from "react-router-dom";
    import image2 from '../ProfileCard/Default_pfp.jpg'
import { logEvent } from "firebase/analytics";
import RingLoader from "react-spinners/RingLoader"; 

    

    function RatingsList() {
        const [isRatingDialogOpen,setIsRatingDialogOpen] = useState(false);
        const [ratings,setRatings] = useState([]);
        const [lastVisible, setLastVisible] = useState(null);
        const [ratingsRef, setRatingsRef] = useState(null);
        const [isMoreAvailable, setIsMoreAvailable] = useState(true);
        const [userData, setUserData] = useState({});
        const [loadMoreLoading, setLoadMoreLoading] = useState(false);
        const [loadingRatings, setLoadingRatings] = useState(false);
        const [ratingSet, setRatingSet] = useState(0);
        

const { name } = useParams();
const [{basket,spec , id, user, onHomeState, headerName}, dispatch] = useStateValue();
const [clicked,setClicked] = useState(0);
const basketName2 = basket && basket[0] ? basket[0].name + '.' + basket[0].surname : '';
const nameTrimmed = name.trim();
const basketName2Trimmed = decodeURIComponent(basketName2.toLowerCase().trim());
        const myProfile = nameTrimmed === basketName2Trimmed;
function capitalizeFirstLetterOfEachWord(str) {
    return str.toLowerCase().split(' ').map(function (word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
            }).join(' ');
        }
        
           

const onClose2 = ()=>{
    setIsRatingDialogOpen(false);
}
        const addGA4Logging = (eventName) => {
            if (myProfile) {
                logEvent(analytics, eventName);
            } else {
                logEvent(analytics, eventName, {
                    receiver_name: spec.name + ' ' + spec.surname,
                    receiver_type: spec.userType,
                    receiver_average_rating: spec?.averageRating,
                    receiver_average_rating_given: spec?.averageRatingGiven,
                    receiver_number_of_ratings: spec?.numberOfRatings,
                    receiver_number_of_ratings_given: spec?.numberOfRatingsGiven,
                    receiver_spec1_exists: spec?.spec1 ? 'true' : "false",
                    receiver_spec2_exists: spec?.spec2 ? 'true' : "false",
                    receiver_spec3_exists: spec?.spec3 ? 'true' : "false",
                    receiver_long_description_set: spec?.aboutMe ? 'true' : 'false',
                    receiver_short_description_set: spec?.description ? 'true' : 'false',
                    receiver_profile_picture_set: spec?.imageURL ? 'true' : 'false',
                    receiver_set_appo: spec?.setAppo ? 'true' : 'false',
                    receiver_on_feed: spec?.onHome ? 'true' : 'false',
                    receiver_price: spec?.price
                })
            }
        }     

        const getClients = async () => {
            setLoadingRatings(true); // Start loading state
            let clientId;

            try {
                if (!myProfile && !spec.id) {
                    const nameDecode = decodeURIComponent(name);
                    const noDotName = nameDecode.replace('.', ' ');
                    const fullName = capitalizeFirstLetterOfEachWord(noDotName);
                    const nameParts = fullName.split(' ');
                    const otherUserName = nameParts[0];
                    let otherUserSurname = '';

                    if (nameParts.length > 1) {
                        otherUserSurname = nameParts.slice(1).join(' ');
                    }

                    const q = query(
                        collection(db, 'Clients'),
                        where('name', '==', otherUserName),
                        where('surname', '==', otherUserSurname)
                    );
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        setUserData(doc.data());
                        clientId = doc.id;
                    });
                } else {
                    clientId = myProfile ? auth.currentUser.uid : spec.id;
                }

                const ratingsRef = collection(db, 'Clients', clientId, 'Ratings');
                setRatingsRef(ratingsRef);
                const q = query(ratingsRef, limit(2));

                const querySnapshot = await getDocs(q);
                let totalRating = 0;
                let totalReviews = querySnapshot.size;
                const ratingsArray = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    totalRating += data.rating;
                    return { id: doc.id, ...data };
                });

                setRatings(ratingsArray);
                const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                setLastVisible(lastVisibleDoc);
                setIsMoreAvailable(querySnapshot.docs.length >= 2);
            } catch (error) {
                console.error('Error fetching clients:', error);
                // Handle error here if needed
            } finally {
                setLoadingRatings(false); // End loading state only after everything is done
            }
        };

          
        useEffect(() => {
               
            getClients();
            
        }, []);
        
        useEffect(() => {
               
            getClients();
            
    }, [ratingSet]);

        function ShowRatings() {
            return loadingRatings
                ?
                <RingLoader color={'#4dffdb'} loading={loadingRatings} size={25} aria-label="Loading Spinner" data-testid="loader" />
 
                :
                ratings.map((rating) => {
                return (
                    <div className="RatingsBox" key={rating.id}>
                        <div className="UpperRatingsBox">
                            <div className='LeftUpperRatingsBox'>
                                <div className="RatingsImageContainer">
                                    <img className="RatingsImage" src={rating.img === '' ? rating.img : image2} alt="rating"></img>
                                </div>
                                <div className="RatingsNameDate">
                                    <div className="RatingsName">{rating.id}</div>
                                    <div className="RatingsDate">
  {rating.date && rating.date.seconds ? new Date(rating.date.seconds * 1000).toLocaleString('sr-Latn-RS') : '   '}
</div>
                                </div>
                            </div>
                            <div className='RightUpperRatingsBox'>
                                <Star2Svg />
                                <div className="RatingsNumber">{parseFloat(rating.rating).toFixed(1)}</div>
                            </div>
                        </div>
                        <div className="LowerRatingsBox">
                            <p className='RatingsComent'>{rating.review}</p>
                        </div>
                    </div>
                )
            })
        }
        const loadMoreRatings = () => {
            setLoadMoreLoading(true);
            addGA4Logging('load_more_ratings');

            if (!lastVisible) {
                setLoadMoreLoading(false); // Ensure loading state is reset if there's nothing to load
                return;
            }

            const nextQuery = query(ratingsRef, startAfter(lastVisible), limit(2));
            getDocs(nextQuery)
                .then(querySnapshot => {
                    const newRatings = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setRatings(prevRatings => [...prevRatings, ...newRatings]);
                    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    setLastVisible(newLastVisible);
                    setIsMoreAvailable(querySnapshot.docs.length >= 2);
                })
                .catch(error => {
                    console.error(error);
                    // Handle any errors here
                })
                .finally(() => {
                    setLoadMoreLoading(false); // Reset loading state after operation completes
                });
        };
          
        const openRatingDialog = () => {
            setIsRatingDialogOpen(true); logEvent(analytics, 'rating_dialog_open', {
                receiver_name: spec.name + ' ' + spec.surname,
                receiver_type: spec.userType,
                receiver_average_rating: spec?.averageRating,
                receiver_average_rating_given: spec?.averageRatingGiven,
                receiver_number_of_ratings: spec?.numberOfRatings,
                receiver_number_of_ratings_given: spec?.numberOfRatingsGiven,
                receiver_spec1_exists: spec?.spec1 ? 'true' : "false",
                receiver_spec2_exists: spec?.spec2 ? 'true' : "false",
                receiver_spec3_exists: spec?.spec3 ? 'true' : "false",
                receiver_long_description_set: spec?.aboutMe ? 'true' : 'false',
                receiver_short_description_set: spec?.description ? 'true' : 'false',
                receiver_profile_picture_set: spec?.imageURL ? 'true' : 'false',
                receiver_set_appo: spec?.setAppo ? 'true' : 'false',
                receiver_on_feed: spec?.onHome ? 'true' : 'false',
                receiver_price: spec?.price
            })
        }
    
        
        return (
            <div className = "RatingsList">
                <div className= "RatingsHeader">
                    <RatingsSvg></RatingsSvg>
                    <span className="RatingsHeaderText"> Rejtinzi i komentari ({ratings.length})</span>
                </div>
                {myProfile || !auth.currentUser ? null : <div className='addRating' onClick={openRatingDialog}>Dodaj rejting +</div>}
                
                {ShowRatings()}
                <button onClick={loadMoreRatings} className={!loadMoreLoading ? "LoadMoreButton" :"LoadMoreButtonLoading"}>Učitaj još</button>
                
                
                
                <RaitingDialog setRatingSet = {setRatingSet} clicked = {clicked} setClicked={setClicked} isOpen={isRatingDialogOpen} onClose2={onClose2} />   
            </div>
        )
    }
    export default RatingsList ;