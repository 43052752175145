import './PsyhotherapistSingUp.css';
import { Link } from "react-router-dom";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase-config'; 
  
const PsyhotherapystSingUp = ({ privacyPoliceAccepted, setPrivacyPoliceAccepted, showPassword, showPasswordImage, hidePasswordImage, togglePasswordVisibility, adress, setAdress, email, setEmail, password, setPassword, name, setName, surname, setSurname, termsAndConditionAccepted, setTermsAndConditionAccepted, userType }) => {
  

  return (
    <div className="InputsDiv3">
      <input className="Inputs" placeholder="ime" value={name} onChange={(e) => setName(e.target.value)} onFocus={() => logEvent(analytics, 'name_input_focused', { type_selected: userType })} />
      <input className="Inputs" placeholder="prezime" value={surname} onChange={(e) => setSurname(e.target.value)} onFocus={() => logEvent(analytics, 'surname_input_focused', { type_selected: userType })} />
      <input className="Inputs" type='email' placeholder="e-mail adresa" value={email} onChange={(e) => setEmail(e.target.value)} onFocus={() => logEvent(analytics, 'email_input_focused', { type_selected: userType })} />
      <div className='PasswordDiv' style={{display: 'flex', width:"100%" }} >
        <input className='Input' type={showPassword ? "text" : "password"} placeholder="lozinka" autocomplete="new-password" onChange={(e) => setPassword(e.target.value)} onFocus={() => logEvent(analytics, 'password_input_focused', { type_selected: userType })} />
          <img style={{position:'relative', right:'2rem'}} width="20rem" src={showPassword ? showPasswordImage : hidePasswordImage} className='passwordButton' onClick={togglePasswordVisibility}/>
      </div>
      <label className='privacyPolicyLabel'>
        Prihvatam <Link to={'../privacy-policy'} onClick={() => { logEvent(analytics, 'go_to_privacy_police', { type_selected: userType })}}>politiku privatnosti</Link> &nbsp;
        <input
          className='privacyPolicyCheckBox'
          name="newsletter"
          type="checkbox"
          onChange={() => { privacyPoliceAccepted ? logEvent(analytics, 'privacy_police_unchecked_registration', { type_selected: userType }) : logEvent(analytics, 'privacy_police_checked_registration', { type_selected: userType }); setPrivacyPoliceAccepted(prev => !prev); }}
          value={privacyPoliceAccepted}
        />
      </label>
      <label className='privacyPolicyLabel'>
        Prihvatam <Link to={'../terms-and-conditions'} onClick={() => { logEvent(analytics, 'go_to_privacy_police', { type_selected: userType }) }}>uslove korišćenja</Link>  &nbsp;&nbsp;
        <input
          name="newsletter"
          type="checkbox"
          className='privacyPolicyCheckBox'
          onChange={() => { termsAndConditionAccepted ? logEvent(analytics, 'terms_and_conditions_unchecked_registration', { type_selected: userType }) : logEvent(analytics, 'terms_and_conditions_checked_registration', { type_selected: userType }); setTermsAndConditionAccepted(prev => !prev);  }}
          value={termsAndConditionAccepted}
        />
      </label>
      
      {/* <input className="Inputs" placeholder="broj telefona" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
    </div>
    )
  }
export default PsyhotherapystSingUp;